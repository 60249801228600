import {getAllCardType, getAllCard} from '@/api/card'

export default {
    namespaced: true,
    state: {
        cardTypes: [],
        cards: {
            1:[]
        }
    },
    mutations: {

        allCard(state, cardTypeId) {

            getAllCard(cardTypeId)
                .then(res => {
                    state.cards[cardTypeId] = res.data.data.cardList
                    state.cardTypes.filter(cardType => cardType.id == cardTypeId)
                        .forEach(cardType => {
                            cardType.cards = res.data.data.cardList
                        })
                })

        },
        allCardType(state) {
            getAllCardType()
                .then(res => {
                    state.cardTypes = res.data.data.cardTypeList
                    state.cardTypes.forEach(value => {
                        this.commit('card/allCard',value.id)
                    })

                })
        },

    },
    actions: {},
    getters: {}
}