<template>
  <div>
    <NavBar></NavBar>
    <div style="height: 78px"></div>
    <router-view></router-view>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar";
export default {
  name: "Index",
  components:{
    NavBar
  }
}
</script>

<style scoped>

</style>