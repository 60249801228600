<template>
  <div style="background-color:white;height: 800px;overflow: scroll">
    <div style="height: 100px;line-height: 100px;font-size: 20px;text-align: left;padding-left: 50px">账户流水</div>
    <div class="search-from">
      <a-row>
        <a-col :span="3" style="line-height: 32px">时间查询:</a-col>
        <a-col :span="5">
          <a-range-picker
              :show-time="{ format: 'HH:mm:ss' }"
              format="YYYY-MM-DD HH:mm:ss"
              :placeholder="['开始时间', '结束时间']"
              @change="onChange"
              @ok="onOk"
          />
        </a-col>
      </a-row>

      <a-row style="margin-top: 20px;">
        <a-col :span="3" style="line-height: 32px">
          订单查询:
        </a-col>
        <a-col :span="5">
          <a-input v-model="searchForm.orderId" placeholder="请输入订单号查询"/>
        </a-col>
        <a-col :span="3">
          <a-select style="width: 200px" allowClear v-model="searchForm.type" @change="handleChange">
            <a-select-option :value="0">
              单卡兑换
            </a-select-option>
            <a-select-option :value="1">
              批量兑换
            </a-select-option>
            <a-select-option :value="2">
              提现
            </a-select-option>
            <a-select-option :value="3">
              加款
            </a-select-option>
            <a-select-option :value="4">
              佣金
            </a-select-option>
            <a-select-option :value="5">
              扣款
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3">
          <a-select style="width: 200px" allowClear v-model="searchForm.status" @change="handleStatusChange">
            <a-select-option :value="1">
              增加余额
            </a-select-option>
            <a-select-option :value="0">
              减少余额
            </a-select-option>

          </a-select>

        </a-col>
      </a-row>
      <a-row style="margin-top: 10px">
        <a-col :offset="3" :span="1">
          <a-button @click="searchOrder" type="primary">
            确认查询
          </a-button>
        </a-col>
      </a-row>
    </div>

    <div style="width: 100%;margin-top: 40px">
      <a-table
          :rowKey="(record)=>record.id"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
      >
        <span slot="type" slot-scope="record">
          {{
            ['单卡兑换', '批量兑换', '提现'][record.type]
          }}
        </span>
      </a-table>
    </div>


  </div>
</template>

<script>
import {searchPlatformFlow} from '@/api/order'

const columns = [
  {
    title: '时间',
    dataIndex: 'createTime',
    scopedSlots: {customRender: 'createTime'},
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: {customRender: 'orderId'},
  },
  {
    title: '批量订单号',
    dataIndex: 'batchOrderId',
    scopedSlots: {customRender: 'batchOrderId'},
  },
  {
    title: '类型',
    scopedSlots: {customRender: 'type'},
  },
  {
    title: '变动金额',
    dataIndex: 'changeAmount',
    scopedSlots: {customRender: 'changeAmount'},
  },
  {
    title: '变动前',
    dataIndex: 'changeBeforeBalance',
    scopedSlots: {customRender: 'changeBeforeBalance'},
  },
  {
    title: '变动后',
    dataIndex: 'changeAfterBalance',
    scopedSlots: {customRender: 'changeAfterBalance'},
  },
  {
    title: '备注',
    dataIndex: 'remarks',
    scopedSlots: {customRender: 'remarks'},
  },

];

export default {
  name: "Index",
  data() {
    return {
      data: [
        {
          key: 1,
          createTime: "2020:11:12",
          orderId: "1",
          bathOrderId: "12",
          type: "单卡兑换",
          changeAmount: "10.00",
          changeBefore: "1.00",
          changeAfter: "2.00",
          remarks: ""

        }
      ],
      loading: false,
      searchForm: {
        createTimeBegin: null,
        createTimeEnd: null,
        orderId: "",
        type: null,
        status: null
      },
      columns,
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        pageSize: 10,
        showSizeChanger: true

      }
    }
  },
  created() {
    this.search()
  },
  mounted() {
  },
  methods: {
    searchOrder() {
      this.pagination.current = 1
      this.search()
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.search()

    },
    search() {
      searchPlatformFlow({
        ...this.searchForm,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }).then(res => {
        this.data = res.data.data.data
        this.pagination.total = res.data.data.total
      })
    },


    onChange(value, dateString) {
      this.searchForm.createTimeBegin = dateString[0]
      this.searchForm.createTimeEnd = dateString[1]
    },
    onOk(value) {
      this.searchForm.createTimeBegin = value[0].format('YYYY-MM-DD HH:mm:ss')
      this.searchForm.createTimeEnd = value[0].format('YYYY-MM-DD HH:mm:ss')
    },
    handleChange(value) {
      this.searchForm.type = value
    },
    handleStatusChange(value) {
      this.searchForm.status = value
    }
  }
}
</script>

<style scoped>

</style>