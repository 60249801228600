<template>
  <div class="phone-setting-container">
    <div style="height: 100%;width: 100%;">
      <div style="width: 100%;margin: 0 auto">
        <a-form-model ref="mainForm" :model="mainForm" :rules="mainForm" v-bind="layout" :labelCol="{ span: '7' }">
          <a-row height="30px">
            <a-col :span="3">
              当前状态：
            </a-col>
            <a-col :span="6">
              <div v-show="mainForm.realNameStatus == 2 "
                 style="color: #7EBA19;height: 30px;line-height: 30px;font-size: 20px;font-weight: bold;margin-top: 0px;text-align: center">
                法人实名认证完成
              </div>
              <div v-show="mainForm.realNameStatus == 1 "
                  style="color: red;height: 30px;line-height: 30px;font-size: 20px;font-weight: bold;margin-top: 0px;text-align: center">
                法人实名认证中
              </div>
              <div v-show="mainForm.realNameStatus == 0 || mainForm.realNameStatus == null "
                  style="color: #f1c232;height: 30px;line-height: 30px;font-size: 20px;font-weight: bold;margin-top: 0px;text-align: center">
                法人实名未认证
              </div>
            </a-col>
            <a-col :span="6">
              <div v-show="mainForm.status == '1' "
                 style="color: #7EBA19;height: 30px;line-height: 30px;font-size: 20px;font-weight: bold;margin-top: 0px;text-align: center">
                企业信息审核通过
              </div>
              <div v-show="mainForm.status == '2' "
                  style="color: red;height: 30px;line-height: 30px;font-size: 20px;font-weight: bold;margin-top: 0px;text-align: center">
                企业信息审核不通过
              </div>
              <div v-show="mainForm.status == '0' "
                  style="color: #f1c232;height: 30px;line-height: 30px;font-size: 20px;font-weight: bold;margin-top: 0px;text-align: center">
                企业信息审核中
              </div>
            </a-col>
            
           </a-row>
          <a-row height="30px">
            <a-col :span="12">
              <a-form-model-item label="企业名称" prop="companyName" >
                <a-input v-model="mainForm.companyName" type="" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="运营网站" prop="website" >
                <a-input v-model="mainForm.website" type="" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="12" v-if="mainForm.realNameStatus == 0 ">
              <a-form-model-item label="法人代表姓名(未实名)" prop="legalPersonName" >
                <a-input v-model="mainForm.legalPersonName" type="text" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="mainForm.realNameStatus == 1 ">
              <a-form-model-item label="法人代表姓名(实名中)" prop="legalPersonName" >
                <a-input v-model="mainForm.legalPersonName" type="text" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="mainForm.realNameStatus == 2 ">
              <a-form-model-item label="法人代表姓名(已实名)" prop="legalPersonName" >
                <a-input v-model="mainForm.legalPersonName" type="text" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
            
            <a-col :span="12">
              <a-form-model-item label="法人代表身份证号" prop="legalPersonIdcard" >
                <a-input v-model="mainForm.legalPersonIdcard" type="text" autocomplete="on"/>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="12">
              <a-form-model-item label="法人代表手机号" prop="legalPersonPhone" >
                <a-input v-model="mainForm.legalPersonPhone" type="text" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="营业执照号" prop="busiLicense" >
                <a-input v-model="mainForm.busiLicense" type="text" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="业务联系人姓名" prop="busiName" >
                <a-input v-model="mainForm.busiName" type="text" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="业务联系人手机号" prop="busiPhone" >
                <a-input v-model="mainForm.busiPhone" type="text" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="12">
              <a-form-model-item label="法人身份证正面原件" prop="legalPersonCardFront" >
                <a-upload
                  accept=".png,.jpg"
                  class="avatar-uploader"
                  action="/pxapi/api/file/upload"
                  @change="legalPersonCardFrontUploadSuccess"
                  >
                  <img width="300px" height="200px" v-if="mainForm.legalPersonCardFront" :src="mainForm.legalPersonCardFront" class="">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </a-upload>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="法人身份证反面原件" prop="legalPersonCardBack" >
                <a-upload
                  accept=".png,.jpg"
                  class="avatar-uploader"
                  action="/pxapi/api/file/upload"
                  @change="legalPersonCardBackUploadSuccess"
                  >
                  <img width="300px" height="200px" v-if="mainForm.legalPersonCardBack" :src="mainForm.legalPersonCardBack" class="">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </a-upload>
              </a-form-model-item>
            </a-col>
          </a-row>
          
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="经办人身份证正面原件" prop="operatorCardFront" >
                <a-upload
                  accept=".png,.jpg"
                  class="avatar-uploader"
                  action="/pxapi/api/file/upload"
                  @change="operatorCardFrontUploadSuccess"
                  >
                  <img width="300px" height="200px" v-if="mainForm.operatorCardFront" :src="mainForm.operatorCardFront" class="">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </a-upload>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="经办人身份证反面原件" prop="operatorCardBack" >
                <a-upload
                  accept=".png,.jpg"
                  class="avatar-uploader"
                  action="/pxapi/api/file/upload"
                  @change="operatorCardBackUploadSuccess"
                  >
                  <img width="300px" height="200px" v-if="mainForm.operatorCardBack" :src="mainForm.operatorCardBack" class="">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </a-upload>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="12">
              <a-form-model-item label="营业执照原件" prop="busiLicensePic" >
                <a-upload
                  accept=".png,.jpg"
                  class="avatar-uploader"
                  action="/pxapi/api/file/upload"
                  @change="busiLicensePicUploadSuccess"
                  >
                  <img width="300px" height="200px" v-if="mainForm.busiLicensePic" :src="mainForm.busiLicensePic" class="">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </a-upload>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="营业执照有效期" prop="limitedTime" >
                <a-input v-model="mainForm.limitedTime" type="text" autocomplete="off"/>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="submitForm">
              保存
            </a-button>
            <a-button type="info" style="margin-left: 10px" @click="legalPersonRealName">
              法人实名认证
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>

    <el-dialog @close="closeQr"
        title="签署承诺书"
        :visible.sync="qrCodeVisible"
        width="30%"
        center>
      <div style="width: 100%">
       <div style="display: flex;justify-content: center"> <vue-qr style="margin: 0 auto" :correctLevel="3" :autoColor="false"  :text="realNameUrl" :size="300" ></vue-qr></div>
      </div>
      <div style="text-align: center">微信扫码签署协议</div>
    </el-dialog>
  </div>
</template>

<script>
import {changeCompanyInfo,queryCompanyInfo,legalPersonRealName} from "@/api/customer"
import VueQr from 'vue-qr'

export default {
  name: "CompanyTab",
  components:{
    VueQr
  },
  data() {
    
    return {
      currentKey:"1",
      mainForm: {
      },
      qrCodeVisible: false,
      realNameUrl: "",
      layout: {
        labelCol: {span: 4},
        wrapperCol: {span: 14},
      },
    };
  },
  created() {
    //默认查询列表数据
    this.loadFormData()
  },
  methods: {
    closeQr(){
      this.$store.commit("user/getUserInfo")
    },
    loadFormData() {
       queryCompanyInfo().then((res) => {
          this.mainForm = res.data.data
          if(this.mainForm.esignSignUrl != null && this.mainForm.esignSignUrl != '' && this.mainForm.realNameStatus != '2'){
            this.realNameUrl = res.data.data.esignSignUrl
            this.qrCodeVisible = true
          }
      })
    },

    legalPersonCardFrontUploadSuccess(res){
      if(res.file.status == "done"){
        this.mainForm.legalPersonCardFront = res.file.response;
      }
    },
    legalPersonCardBackUploadSuccess(res){
      if(res.file.status == "done"){
        this.mainForm.legalPersonCardBack = res.file.response;
      }
    },
    operatorCardFrontUploadSuccess(res){
      if(res.file.status == "done"){
        this.mainForm.operatorCardFront = res.file.response;
      }
    },
    operatorCardBackUploadSuccess(res){
      if(res.file.status == "done"){
        this.mainForm.operatorCardBack = res.file.response;
      }
    },
    busiLicensePicUploadSuccess(res){
      if(res.file.status == "done"){
        this.mainForm.busiLicensePic = res.file.response;
      }
    },
    
    submitForm() {
      changeCompanyInfo(this.mainForm).then(() => {
        this.$message.success("保存成功")
      })
    },

    legalPersonRealName() {
      if(this.mainForm == null || this.mainForm.id == null){
        this.$message.warn("请先保存企业认证信息");
        return;
      }
      legalPersonRealName({
        idCard: this.mainForm.legalPersonIdcard,
        name: this.mainForm.legalPersonName
      }).then((res) => {
        this.realNameUrl = res.data.data.realNameUrl
        this.qrCodeVisible = true
      })
    },
  },
}
</script>

<style scoped>


.phone-setting-container {
  padding: 0px 20px 20px;
  height: 600px;
}


</style>