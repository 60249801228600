<template>
  <div style="background-color:white;height: 800px;overflow: scroll">
    <div style="height: 100px;line-height: 100px;font-size: 20px;text-align: left;padding-left: 10px">订单统计
    </div>
    <div class="search-from">
      <a-row>
        <a-col :span="2" style="line-height: 32px">时间:</a-col>
        <a-col :span="7">
          <a-range-picker style="width: 100%"
                        :default-value="[
                          moment(),
                          moment().add(1,'days')
                        ]"
                          :showTime="showTime"
                          :show-time="{ format: 'HH:mm' }"
                          format="YYYY-MM-DD"
                          :placeholder="['开始时间', '结束时间']"
                          @change="onChange"
                          @ok="onOk"
          />
        </a-col>
        <a-col :offset="1" :span="1">
          <a-button @click="search" type="primary">
            查询
          </a-button>
        </a-col>
      </a-row>
    </div>

    <div style="width: 100%;margin-top: 10px">
      <a-table
          :rowKey="(record)=>record.orderId"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
          size="small"
      >

      </a-table>
    </div>

  </div>
</template>

<script>
import emuns from '@/constant/emuns'
import orderApi from '@/api/order'
import moment from "moment"

const columns = [

  {
    title: '日期',
    dataIndex: 'create_time',
    scopedSlots: {customRender: 'create_time'},
    width: '150px',
  },
  {
    title: '卡名称',
    dataIndex: 'card_name',
    scopedSlots: {customRender: 'card_name'},
    width: '200px',
  },
  {
    title: '成功订单数',
    dataIndex: 'cnt',
    scopedSlots: {customRender: 'cnt'},
  },
  {
    title: '提交总面值',
    dataIndex: 'total_amt',
    scopedSlots: {customRender: 'total_amt'},
  },
  {
    title: '成功总面值',
    dataIndex: 'final_amt',
    scopedSlots: {customRender: 'final_amt'},
  },
  {
    title: '用户可得',
    dataIndex: 'user_amt',
    scopedSlots: {customRender: 'user_amt'},
  },
  

];

export default {
  name: "Index",
  data() {
    return {
      dialogFormVisible: false,
      mainForm: {},
      order: {},
      orderStatus: emuns.orderStatus,
      type: emuns.orderType,
      data: [],
      loading: false,
      searchForm: {
        createTimeBegin: null,
        createTimeEnd: null,
        orderId: "",
        cardAccount: "",
      },
      columns,
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        pageSize: 10,
        showSizeChanger: true,
        
      },
      userInfo: {},
      orderStatsInfo: {},
      dateFormat: 'YYYY-MM-DD',
      showTime: {
        format: "HH:mm:ss",
        defaultValue: [
          moment(),
          moment().add(1,'days'),
        ],
      },
    }
  },
  created() {
    
    this.getToday()
    // this.userInfo = this.$store.state.user.userInfo
    this.loadData()
  },
  mounted() {
  },
  methods: {
    moment,
    getCurrentData() {
      return new Date().toLocaleDateString();
    },

    getToday() {
      let time = new Date();
      const yy = time.getFullYear(); //获取完整的年份(4位,1970-???)
      const M = time.getMonth() + 1; //获取当前月份(0-11,0代表1月),
      const d = time.getDate(); //获取当前日(1-31)
      // const H = time.getHours(); //获取当前小时数(0-23)
      // const m = time.getMinutes(); //获取当前分钟数(0-59)
      // const s = time.getSeconds(); //获取当前秒数(0-59)
      
      // 小于9的，在前面加0
      const MM = M > 9 ? M : "0" + M;
      const dd = d > 9 ? d : "0" + d;
      // const HH = H > 9 ? H : "0" + H;
      // const mm = m > 9 ? m : "0" + m;
      // const ss = s > 9 ? s : "0" + s;
      // 指定的过去时间
      const begintime = yy + "-" + MM + "-" + dd + " 00:00:00";
      // 当前时间
      const endtime = yy + "-" + MM + "-" + dd + " 23:59:59";

      // return [begintime, endtime];
      this.searchForm.createTimeBegin = begintime;
      this.searchForm.createTimeEnd = endtime;
    },

    search() {
      this.pagination.current = 1
      this.loadData()
    },
    loadData() {
      this.loading = true
      orderApi.userOrderStat({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        createTimeBegin: this.searchForm.createTimeBegin,
        createTimeEnd: this.searchForm.createTimeEnd,
      }).then(res => {
        this.data = res.data.data.data
        this.loading = false
        this.pagination.total = res.data.data.total
      })
    },

    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData()

    },
    fetch(params = {}) {
      console.log('params:', params);
    },
    searchOrder() {
      console.log(this.searchForm)
    },
    onChange(value, dateString) {
      this.searchForm.createTimeBegin = dateString[0]
      this.searchForm.createTimeEnd = dateString[1]
    },
    onOk(value) {
      this.searchForm.createTimeBegin = value[0].format('YYYY-MM-DD HH:mm:ss')
      this.searchForm.createTimeEnd = value[1].format('YYYY-MM-DD HH:mm:ss')
    },
    handleChange(value) {
      this.searchForm.type = value
    },
    handleStatusChange(value) {
      this.searchForm.status = value
    }
  }
}
</script>

<style scoped>

</style>