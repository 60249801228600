<template>
  <div class="sell-card-container">
    <div  class="">
        <!-- <img src="~@/assets/image/contactUs2.jpg" alt="" > -->
    </div>
    <div class="about">
        <div class="wrap">
            <!-- <h3>企业介绍</h3> -->
            <div class="p-text">
                <div class="p-tl">
                    <div>
                      <p align="left">
                        联系我们：400-8066-173（免费客服电话）<br/>
                        客服时间：周一至周日24小时<br/>
                        联系邮箱：oy_173xiaoka@163.com<br/>
                        QQ：575716232,1163002572<br/>
                        公司地址：<br/>
                        江苏省镇江市句容市开发区碧海路3号华东科技新城3幢511号<br/>
                      </p>
                    </div>
                </div>
                <div class="p-tr"><img src="~@/assets/image/address.png" alt="企业地址" style="height:120%;width:280%"></div>
            </div>
        </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Index",
  data() {
    return {
      customRate: 0,//供货折扣
      isSingleSubmit: true,//是否单笔订单
      currentCardPriceId: 0,//当前面值id
      singeSubmitCard: {
        account: "",
        password: "",
      },
      multiSubmiteCardList: [],
      isReadProtocol: false,
      isAccessProtocol: false,
      discount: "",
      currentCardTypeId: 0,
      currentCardId: 0,
      cardTypeList: [],
      currentCardList: [],
      currentCardPriceList: [],
      cardTypeId: ""
    }
  },
  components:{
    Footer
  },
  created() {
    
  },

  mounted() {
    // console.log("=============" + this.cardTypeId)
    // this.selectCardType(this.cardTypeId)
  },
  watch:{
    // "$route": "monitorAPP"
    $route: {
      handler:function(){
        this.$nextTick(function(){  //页面加载完成后执行
          // console.log("=============" + val+oldVal)
          this.selectCardType(this.$route.query.cardTypeId)
        })
      },
      // 深度观察监听
      deep: true
    }
  },
  computed: {
    //当前面值
    currentCardPrice() {
      if (this.currentCardPriceId == 0) {
        return {}
      }
      return this.currentCardPriceList.filter(value => value.id === this.currentCardPriceId)[0]
    },
    //当前选择卡种
    currentCard() {
      if (this.currentCardId == 0) {
        return {}
      }
      return this.currentCardList.filter(value => value.id === this.currentCardId)[0]
    },
    currentCarmi() {
      let carmiRule = this.currentCard.carmiRule;
      if (carmiRule == null) {
        return {}
      }
      return {
        accountLen: carmiRule.split(":")[0],
        passwordLen: carmiRule.split(":")[1]
      }
    }
  },

  methods: {
    

  }
}
</script>

<style scoped>

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

div {
    display: block;
}

.wechat-banner[data-v-e6981518] {
    position: relative;
    width: 100%;
    height: 350px;
    
}
   
.public-contern[data-v-e6981518] {
    width: 100%;
    background: #fff;
    overflow: hidden;
}

.wechat-banner .w1320[data-v-e6981518] {
    position: relative;
}

.w1320 {
    width: 1320px;
    margin: 0 auto;
}

.about {
    padding-top: 30px;
    margin-bottom: 30px;
    background: #fdfdfd;
}
* {
    margin: 0;
    padding: 0;
    font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
}

div {
    display: block;
}

.about .wrap {
    position: relative;
    height: 478px;
}
.wrap {
    width: 1200px;
    margin: 0 auto;
}

.about .wrap .p-text {
    margin-top: 40px;
    color: #514644;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 1;
}

.about .wrap .p-text .p-tl {
    width: 40%;
    margin-right: 20px;
}

.about .wrap .p-text p {
    margin-bottom: 20px;
    line-height: 32px;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.about .wrap .p-text .p-tr {
    width: 20%;
    text-align: left;
}

.about .wrap .p-text {
    margin-top: 40px;
    color: #514644;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 1;
}
</style>

