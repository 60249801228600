<template>
  <div>
    <a-button :disabled="isSend" type="primary" style="width: 102px" @click="sendCode()">
      {{ text }}
    </a-button>
  </div>
</template>

<script>
export default {
  name: "CodeButton",
  data() {
    return {
      disabled: false,
      isSend: false,
      defaultText: "发送验证码",
      text: "发送验证码"
    }
  },
  methods: {
    sendCode() {
      if (this.isSend) {
        return
      }
      let success = false
      this.$emit('click',(value)=>{
        success = value
      })
      if (success){
        this.isSend = true
        let time = 60
        let timer = setInterval(() => {
          this.text = --time
          if (time === 0) {
            this.text = this.defaultText
            this.isSend = false
            clearInterval(timer)
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped>

</style>