import {userInfo, signOut} from '@/api/customer'

export default {
    namespaced: true,
    state: {
        userInfo: {}
    },
    mutations: {
        getUserInfo(state, calls) {
            userInfo()
                .then(res => {
                    state.userInfo = res.data.data
                    if (res.data.data.id !== null) {
                        if (calls!== undefined && calls.callback !== undefined) {
                            calls.callback()
                        }
                    } else {
                        if (calls !== undefined && calls.failCallBack !== undefined) {
                            calls.failCallBack()
                        }
                    }
                })

        },
        signOut(state) {
            signOut().then(() => {
                state.userInfo = {}
                localStorage.removeItem("token")
                location.reload()
            })
        }

    },
    actions: {},
    getters: {
        isLogin: state => {
            return state.userInfo.id !== null && state.userInfo.id !== undefined
        },
        realNameStatus: state => {
            return state.userInfo.realNameStatus == 2
        }
    }
}