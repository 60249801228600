<template>
  <div style="background-color:white;height: 800px;overflow: scroll;padding: 0 20px 30px;">
    <div style="height: 100px;line-height: 100px;font-size: 20px;text-align: left">批量订单-订单详情</div>
    <div class="info-area">
      <div class="order-info">
        <span style="font-size: 24px;font-weight: 500;color: #000">单号：{{ this.batchOrderInfo.batchOrderId }} </span>
        <span style="font-size: 22px;font-weight: 500;color: rgb(153, 153, 153)">| 提交时间：{{
            this.batchOrderInfo.createTime
          }}</span>
        <a style="position: absolute;right: 20px" @click="goBack">返回批量订单</a>
      </div>
      <div class="order-card-info">
        此次交易共<span
          style="color: #FD8019">{{ this.batchOrderInfo.total }} 张</span>卡，交易成功：{{ this.batchOrderInfo.successCount }}
        张， 失败<span style="color: #D25851"> {{ this.batchOrderInfo.failCount }} 张</span>，您共获得：<span
          style="color: #FD8019"> {{this.getMoney}}元</span>， 款项已经打入您的账户余额中，你可以到账户总览查看。
      </div>
    </div>

    <div style="width: 100%;margin-top: 40px">
      <a-table
          :rowKey="(record)=>record.orderId"
          :columns="columns"
          :data-source="this.batchOrderInfo.data"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
      >

        <span slot="status" slot-scope="status">
          {{
            orderStatus[status]
          }}
        </span>
        <span slot="orderType" slot-scope="orderType">
          {{
            type[orderType]
          }}
        </span>
        <span slot="finalGet" slot-scope="record">
          {{record.finalPrice * record.userRate / 100}}
        </span>
      </a-table>
    </div>


  </div>
</template>

<script>
import emuns from '@/constant/emuns'
import orderApi from '@/api/order'

const columns = [
  {
    title: '卡种',
    dataIndex: 'cardName',
    scopedSlots: {customRender: 'cardName'},
  },
  {
    title: '卡号',
    dataIndex: 'cardAccount',
    scopedSlots: {customRender: 'cardAccount'},
  },
  {
    title: '卡密',
    dataIndex: 'cardPassword',
    scopedSlots: {customRender: 'cardPassword'},
  },
  {
    title: '提交面值',
    dataIndex: 'price',
    scopedSlots: {customRender: 'price'},
  },
  {
    title: '实际面值',
    dataIndex: 'finalPrice',
    scopedSlots: {customRender: 'finalPrice'},
  },
  {
    title: '实际可得',
    scopedSlots: {customRender: 'finalGet'},
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'},
  },
  {
    title: '提交时间',
    dataIndex: 'createTime',
    scopedSlots: {customRender: 'createTime'},
  },
  {
    title: '处理时间',
    dataIndex: 'processTime',
    scopedSlots: {customRender: 'processTime'},
  },
  {
    title: '备注',
    dataIndex: 'remarks',
    scopedSlots: {customRender: 'remarks'},
  },

];

export default {
  name: "Index",
  data() {
    return {
      batchOrderInfo: {
        data:[],
      },

      batchOrderInfo2: {
        data:[
          {
            status:1,
            finalPrice:100,
            userRate:97,

          }
        ],
      },
      batchOrderId: "",
      orderStatus: emuns.orderStatus,
      type: emuns.orderType,
      data: [],
      loading: false,
      columns,
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        pageSize: 10,
        showSizeChanger: true

      }
    }
  },
  created() {
    this.batchOrderId = this.$route.query.batchOrderId
    this.loadData()
  },
  computed: {
    getMoney() {
      return this.batchOrderInfo.data.filter(value => value.status == 1).map(value => value.finalPrice * value.userRate / 100).reduce((value1,value2) => value1+ value2,0)
    }
  },
  mounted() {
  },
  methods: {
    search() {
      this.pagination.current = 1
      this.loadData()

    },
    goBack(){
      this.$router.go(-1)
    },
    loadData() {
      this.loading = true
      orderApi.getBatchOrderInfo({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        batchOrderId: this.batchOrderId
      }).then(res => {
        console.log(res.data.data)
        this.batchOrderInfo = res.data.data
        this.pagination.total = res.data.data.total
      }).finally(() => {
        this.loading = false
      })
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData()

    },
    fetch(params = {}) {
      console.log('params:', params);
    },
    searchOrder() {
      console.log(this.searchForm)
    },
    onChange(value, dateString) {
      this.searchForm.createTimeBegin = dateString[0]
      this.searchForm.createTimeEnd = dateString[1]
    },
    onOk(value) {
      this.searchForm.createTimeBegin = value[0].format('YYYY-MM-DD HH:mm:ss')
      this.searchForm.createTimeEnd = value[0].format('YYYY-MM-DD HH:mm:ss')
    },
    handleChange(value) {
      this.searchForm.type = value
    },
    handleStatusChange(value) {
      this.searchForm.status = value
    }
  }
}
</script>

<style scoped>
.order-card-info {
  width: 100%;
  height: 40px;
  line-height: 40px;
  box-shadow: 0 6px 15px #EEEEEE;
  border-radius: 0 0 8px 8px;
  background: #e9fdff;
  position: absolute;
  bottom: 0px;
  color: #28BAFF;
  text-align: left;
  padding-left: 20px;
  font-size: 16px;

}

.order-info {
  height: 88px;
  line-height: 88px;
  position: relative;
  text-align: left;
  padding-left: 20px;
}

.info-area {

  height: 120px;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
}

</style>