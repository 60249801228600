<template>
  <div class="phone-setting-container">
    <div style="height: 100%;width: 100%;overflow: scroll">
      <div v-if="this.$store.state.user.userInfo.realNameStatus!=2" class="phone-setting">
        <div class="info">
          <div class="phone-icon">
            <a-icon type="credit-card"/>
          </div>
          <div class="text-info">
            <div
                style="color: #EB554E;height: 50px;line-height: 50px;font-size: 15px;font-weight: bold;margin-top: 20px;text-align: left">
              您还没有进行实名认证，无法添加支付宝账号!
            </div>
            <div style="text-align: left;color: #B1B1B0">
              添加支付宝账号前必须先进行实名认证<br/>
              如有问题，请联系客服咨询
            </div>
            <div style="margin-top: 20px">
              <a-button @click="gotoRealName" style="margin-left: -175px" icon="idcard" type="primary">
                实名认证
              </a-button>
            </div>
          </div>

        </div>
      </div>
      <div v-if="this.$store.state.user.userInfo.realNameStatus==2 && this.userBankCardList.length==0">
        <div @click="showDiglog">
          <div class="add-card">
            <a-icon type="credit-card"/>
          </div>
          <div style="margin: 10px auto 0;cursor: pointer;color: #535353">添加支付宝账号</div>
        </div>
      </div>
      <div style="width: 100%;" v-if="this.userBankCardList.length > 0">
        <div class="" v-for="item in this.userBankCardList" :key="item.id" >
          <div class="card-item" v-if="item.cardType == 2"  >
            <div style="height: 100%;line-height: 150px;font-size: 60px;color: #FDAE35;margin-left: 20px"><a-icon type="credit-card" /></div>
            <div style="margin-left: 30px;text-align: left">
              <div style="margin-top: 35px;font-size: 24px">{{item.realName}} <a-tag color="green">
                已实名
              </a-tag></div>
              <div style="font-size: 16px"><span style="color: #A0A0A0">支付宝账号：</span><span style="color: #535353">{{ item.cardNumber }}</span></div>
            </div>
            <div style="display: flex;font-size: 45px;line-height: 150px;position: relative;right: -250px;">
              <div >
                <a-popconfirm
                    title="您确定删除该卡吗?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="deleteCardById(item.id)"
                >
                  <a-icon  style="cursor: pointer" type="delete" />
                </a-popconfirm>

              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px" @click="showDiglog">
          <a-button style="margin-left: -535px" type="primary"  icon="file-add" size="large" >继续添加支付宝</a-button>
        </div>

      </div>

      <el-dialog
          title="添加支付宝"
          :visible.sync="dialogVisible"
          width="60%"
      >
        <a-form-model ref="ruleForm" :model="ruleForm" v-bind="layout" :labelCol="{ span: '7' }">
          <a-row height="30px">
            <a-col :span="12">
              <a-form-model-item label="认证实名">
                <div style="text-align: left">{{ this.$store.state.user.userInfo.realName }}</div>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="身份证号码">
                <div style="text-align: left">{{ this.$store.state.user.userInfo.idCard }}</div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row height="30px">
            <a-col :span="12">
              <a-form-model-item label="支付宝账号">
                <el-input style="width: 250px;" v-model="addCardForm.cardNumber" @change="cardChange" placeholder="请输入支付宝账号"></el-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row height="30px">
            <a-col :span="12">
              <a-form-model-item label="更多">
                <el-input style="width: 250px;" v-model="addCardForm.remark" type=""
                        placeholder="其它附加信息，非必填"></el-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="交易密码">
                <el-input style="width: 250px;" v-model="addCardForm.transactionPassword" type="password"
                              placeholder="请输入交易密码"></el-input>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div slot="footer" style="width: 100%;display: flex;justify-content: center">
          <el-button @click="addCard" type="primary">确 定</el-button>
        </div>
      </el-dialog>


    </div>

  </div>
</template>

<script>
import {bankList, getBankCodeByCardNumber} from '@/api/bank'
import {addCard, getUserAllCards,deleteUserCardById} from '@/api/customer'

export default {
  name: "AliPaySettingTab",
  data() {
    return {
      userBankCardList: [],
      addCardForm: {
        cardNumber: "",
        cardBankCode: "",
        transactionPassword: "",
        cardType: "2"
      },
      value: "",
      bankList: [],
      dialogVisible: false,
      ruleForm: {
        pass: '',
        checkPass: '',
        age: '',
      },
      layout: {
        labelCol: {span: 4},
        wrapperCol: {span: 14},
      },
    }
  },
  created() {

    bankList()
        .then(res => {
          this.bankList = res.data.data.bankList
        })
    this.loadUserCards()
  },
  methods: {
    deleteCardById(id){
      deleteUserCardById(id)
      .then(()=>{
        this.$message.success("删除成功")
        this.loadUserCards()
      })

    },
    loadUserCards(){
      getUserAllCards()
          .then(res => {
            this.userBankCardList = res.data.data.userBankCardList
          })
    },
    gotoRealName() {
      this.$emit("toRealName")
    },
    addCard() {
      addCard(this.addCardForm)
          .then(() => {
            this.$message.success("添加成功")
            this.dialogVisible = false
            this.loadUserCards()

          })

    },
    cardChange() {
      let cardNumber = this.addCardForm.cardNumber;
      if (cardNumber.length > 10) {
        getBankCodeByCardNumber(cardNumber)
            .then(res => {
              if (res.data.data != null) {
                this.addCardForm.cardBankCode = res.data.data
              }

            })
      }
    },
    showDiglog() {
      this.dialogVisible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },

}
</script>

<style scoped>
.card-item{
  width: 700px;
  height: 150px;
  border-radius: 8px;
  background-color: #F3F7FE;
  display: flex;
  margin: 20px auto 0;

}
.ant-form-item-children {
  margin-left: 0px;
}

.add-card {
  width: 300px;
  height: 160px;
  border: 1px dashed #bfcbd9;
  border-radius: 3px;
  margin: 0 auto;
  font-size: 60px;
  cursor: pointer;
  line-height: 160px;
}

.text-info {
  width: 100%;
  height: 100%;
  padding-left: 20px;
}

.phone-icon {
  height: 100%;
  width: 100px;
  line-height: 200px;
  font-size: 90px;
  color: #EB554E;
}

.info {
  width: 400px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.phone-setting {
  width: 600px;
  height: 300px;
  margin: 0 auto;
  background-color: #F8FCF1;
  padding-top: 50px;
}

.phone-description {
  width: 600px;
  height: 300px;
  margin: 0 auto;

}

.phone-setting-container {
  padding: 60px 20px 20px;
  height: 600px;
}

</style>