<template>
  <div class="help-container">
    <a-list :bordered="true"
            style="width: 260px;height: 562px;border: 1px solid #ddd;border-radius: 6px;box-shadow: 0 15px 30px gainsboro;position: absolute;top:40px"
            item-layout="horizontal" :data-source="categoryList">
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta>
          <a @click="selectArticleCategory(item.id)" v-if="currentCategoryId !==null && currentCategoryId == item.id"
             style="color: #1890ff;" slot="title">{{ item.name }}</a>
          <a @click="selectArticleCategory(item.id)" v-else slot="title">{{ item.name }}</a>
        </a-list-item-meta
        >
      </a-list-item>
    </a-list>


    <a-list
        style="position: absolute;width: 1028px;height: 878px;top: 40px;right: 10px;border: 1px solid #ddd;border-radius: 6px;box-shadow: 0 15px 30px gainsboro;padding: 10px 10px"
        :pagination="pagination" :data-source="articleList">
      <div slot="header" style="text-align: left;color: #1890ff;font-size: 24px">{{currentCategoryName}}</div>
      <a-list-item slot="renderItem" key="item.title" slot-scope="item">
        <a-list-item-meta>
          <div @click="gotoInfo(item.id)" slot="title" class="article-item"
               style="height: 40px;line-height: 40px;position: relative;cursor: pointer;font-size: 16px">
            <div style="height: 100%;position: absolute;left: 40px">
              {{ item.title }}
            </div>
            <div style="height: 100%;position: absolute;right: 40px">
              {{ item.updateTime }}
            </div>
          </div>
        </a-list-item-meta>
      </a-list-item>
    </a-list>


  </div>

</template>

<script>
import helpApi from '@/api/help'

export default {
  name: "Index",
  data() {
    return {
      currentCategoryId: 0,
      currentCatgoryName:"",
      categoryList: [],
      articleList: [],
      pagination: {
        onChange: page => {
          this.pagination.pageNo = page
          this.listArticle()
        },
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
    };
  },
  created() {
    this.loadAllCategory()
        .then(() => {
          let categoryId = this.$route.query.categoryId;
          if (categoryId !== undefined && categoryId !== null) {
            this.currentCategoryId = categoryId
          } else {
            if (this.categoryList.length > 0) {
              this.currentCategoryId = this.categoryList[0].id
            }
          }
          this.listArticle()
        })
  },
  computed:{
    currentCategoryName(){
      return this.categoryList.filter(value => value.id == this.currentCategoryId)[0].name
    }
  },
  methods: {
    gotoInfo(id) {
      this.$router.push({
        path: "/helpInfo",
        query: {
          id,
          categoryId: this.currentCategoryId
        }
      })
    },
    selectArticleCategory(categoryId) {
      this.currentCategoryId = categoryId;
      this.pagination.pageNo = 1
      this.listArticle()
    },
    listArticle() {
      helpApi.articleList(this.currentCategoryId, this.pagination.pageNo, this.pagination.pageSize)
          .then(res => {
            console.log(res.data.data)
            this.articleList = res.data.data.data
            this.pagination.total = res.data.data.total
          })
    },

    loadAllCategory() {
      return new Promise((resolve, reject) => {
        helpApi.articleCategoryList()
            .then(res => {
              this.categoryList = res.data.data.data
              resolve(res)
            }).catch(() => {
          reject()
        })
      });

    }
  }
}
</script>

<style scoped>
.article-item:hover {
  color: #1890ff;
}

.ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;;
}

.help-container {
  width: 1320px;
  margin: 0 auto;
  min-height: 1000px;
  position: relative;
}

</style>