<template>
  <div style="background-color:white;">
    <div style="height: 100px;line-height: 100px;font-size: 20px;text-align: left;padding-left: 50px">我要提现</div>
    <div class="from">
      <a-form-model ref="drawForm" :model="drawForm"  v-bind="layout">
        <a-form-model-item  label="可提现余额" style="text-align: left;" >
          <span>￥{{ withDrawBalance }}</span>
        </a-form-model-item>
        <a-form-model-item label="收款人" style="text-align: left">
          <span>{{ this.$store.state.user.userInfo.realName }}</span>
          <a-tag v-if="this.$store.state.user.userInfo.realNameStatus==2"  color="green" style="margin-left: 10px">
            已实名
          </a-tag>
          <a-tag v-else  color="red" style="margin-left: 10px">
            未实名
          </a-tag>
        </a-form-model-item>
        <a-form-model-item label="提现方式:" style="text-align: left">
          <a-select v-model="drawForm.type" style="width: 380px">
            <a-select-option value="1">
              银行卡
            </a-select-option>
            <a-select-option value="2">
              支付宝
            </a-select-option>
          </a-select>

        </a-form-model-item>
        <a-form-model-item label="提示:" style="text-align: left;color: #93C63B">
          单笔提款限额{{maxWithDrawAmount}}元，每笔收{{fee}}元手续费
        </a-form-model-item>

        <a-form-model-item label="账号选择:" style="text-align: left">
          <a-select v-model="drawForm.userBankId" style="width: 380px">
            <a-select-option v-for="item in userCards" :key="item.id" :value="item.id">
              {{ item.bankName }}   {{item.cardNumber | cardEnc}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="提现金额:" style="text-align: left">
          <a-row>
            <a-col :span="20">
              <a-input-number v-model="drawForm.amount" :min="0" style="width: 380px" :step="0.1" />
            </a-col>
            <a-col :span="4">
              <a-button type="primary" @click="clickDrawAll" >
                全部提现
              </a-button>
            </a-col>

          </a-row>

        </a-form-model-item>
        <a-form-model-item label="交易密码:" style="text-align: left">
          <a-input-password v-model="drawForm.transactionPassword"  style="width: 380px"  placeholder="交易密码" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button  :loading="submitBtnLoading" type="primary" @click="submitForm">
            立即提现
          </a-button>

        </a-form-model-item>
      </a-form-model>

    </div>


  </div>
</template>

<script>
import {getUserAllCards} from '@/api/customer'
import {get} from '@/api/config'
import configKey from "@/constant/configKey";
import orderApi from '@/api/order'
export default {
  name: "Index",

  data() {
    return {
      submitBtnLoading: false,
      maxWithDrawAmount:null,
      fee:null,
      drawForm: {
        userBankId: null,
        amount: 0,
        transactionPassword: null,
        type: "1",
      },
      layout: {
        labelCol: {span: 4},
        wrapperCol: {span: 14},
      },
      userCards:[]
    };
  },
  computed:{
    withDrawBalance(){
      return parseFloat(this.$store.state.user.userInfo.balance) - parseFloat(this.$store.state.user.userInfo.frozenAmount)
    }
  },
  created() {
    this.$store.commit("user/getUserInfo")
    this.getUserCards()
    this.getMaxDrawAmount()
    this.getFee()
  },
  mounted() {
    //用户未实名的话就去实名认证
    if (this.$store.state.user.userInfo.realNameStatus != 2){
      this.$message.warn("请完成实名认证")
      this.$router.push("/center/baseManage?tab=relName")
    }
    //设置交易密码
    let transactionPassword = this.$store.state.user.userInfo.transactionPassword;
    if (transactionPassword == null || transactionPassword == ""){
      this.$message.warn("请设置交易密码")
      this.$router.push("/center/baseManage?tab=passwordManage&passwordTab=2")
    }
  },
  methods: {
    clickDrawAll(){
      if (this.withDrawBalance > this.maxWithDrawAmount){
        //this.drawForm.amount = this.maxWithDrawAmount
      }else {
        this.drawForm.amount = this.withDrawBalance
      }
    },
    getMaxDrawAmount(){
      get(configKey.MAX_WITH_DRAW)
      .then(res => {
        this.maxWithDrawAmount = parseFloat(res.data.data)
      })
    },
    getFee(){
      get(configKey.FEE)
          .then(res => {
            this.fee = parseFloat(res.data.data)
          })
    },
    getUserCards(){
      getUserAllCards()
      .then(res => {
        this.userCards = res.data.data.userBankCardList
      })
    },
    submitForm() {
      this.submitBtnLoading = true
      orderApi.submitDraw(this.drawForm)
      .then(()=>{
        this.$router.push("/center/withdraw/record")
      })
      .finally(()=>{
        this.submitBtnLoading = false
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
}
</script>

<style scoped>
.from{
  width: 800px;
  margin: 20px auto;
  padding-bottom: 100px;
}

</style>