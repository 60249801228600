<template>
  <div id="nav">
    <CardTypePreView @onmouseout="onCardMouseOut" :display="cardContainerDisplay"></CardTypePreView>
    <a-row>
      <a-col :span="4">
        <div id="logo">
          <a href="/"><img src="~@/assets/image/logo.png" alt="logo"
                           style="margin-top: 15px;width:160px;height:50px" ></a>
        </div>
      </a-col>
      <a-col :span="16">
        <a-row>
          <a-col :span="4">
            <div id="home" class="menu-item">
              <a-icon type="home"/>
              <router-link to="/">平台首页</router-link>
            </div>
          </a-col>
          <a-col :span="3">
            <div id="sellCard" class="menu-item">
              <a-icon type="credit-card"/>
              <router-link to="/sellCard">我要销卡</router-link>
            </div>
          </a-col>
         <a-col id="account" :span="3">
           <div class="menu-item">
             <a-icon type="wechat"/>
             <router-link to="/webchat">公众号</router-link>
           </div>
         </a-col>
         <a-col :span="3">
           <div class="menu-item">
             <a-icon type="usb"/>
             <router-link to="/">API对接</router-link>
           </div>
         </a-col>
          <a-col id="account" :span="3">
            <div class="menu-item">
              <a-icon type="user"/>
              <router-link to="/center/accountinfo">个人中心</router-link>
            </div>
          </a-col>
          <a-col :span="3">
           <div class="menu-item">
             <a-icon type="user-add"/>
             <router-link to="/contactus">联系我们</router-link>
           </div>
         </a-col>
         <a-col :span="3">
           <div class="menu-item">
             <a-icon type="user"/>
             <router-link to="/aboutus">关于我们</router-link>
           </div>
         </a-col>
        </a-row>
      </a-col>
      <a-col :span="4">
        <div v-if="!this.$store.getters['user/isLogin']" class="signBtnGroup">
          <a-row>
            <router-link to="/signin">登录</router-link>
            <label> | </label>
            <router-link to="/signup">注册</router-link>
            <label> | </label>
            <router-link to="/help">帮助中心</router-link>
          </a-row>
        </div>
        <div v-else class="signBtnGroup">
          <a-row>
            <a  @click="logout" >退出</a>
            <label> | </label>
            <router-link to="/help">帮助中心</router-link>
          </a-row>
        </div>


      </a-col>
    </a-row>
  </div>
</template>

<script>
import CardTypePreView from "@/components/CardTypePreView";
export default {
  name: "NavBar",
  components: {
    CardTypePreView
  },
  created() {
    this.$store.commit('user/getUserInfo')
    this.$store.commit('card/allCardType')
  },
  mounted() {

    let sellCardBtn = document.getElementById("sellCard");
    sellCardBtn.onmouseover = () => {
      this.cardContainerDisplay = "";
    }
    document.getElementById("account").onmouseover= ()=>{
      this.cardContainerDisplay = "none";
    }
    document.getElementById("home").onmouseover= ()=>{
      this.cardContainerDisplay = "none";
    }

  },
  data() {
    return {
      cardContainerDisplay: "none"
    }
  },
  methods:{
    logout(){
      this.$store.commit('user/signOut')
    },
    onCardMouseOut(){
      this.cardContainerDisplay = "none"
    }
  }
}
</script>

<style scoped>

#nav {
  height: 78px;
  width: 100%;
  background-color: #26AAFE;
  position: fixed;
  display: block;
  z-index: 999;
}

#logo {
  height: 78px;
}

.signBtnGroup {
  height: 78px;
  line-height: 78px;
}

.menu-item {
  height: 78px;
  line-height: 78px;
  color: white;
  font-size: 17px;
}

.menu-item a {
  color: white;
}

.menu-item a:hover {
  color: white;
}

.router-link-active {
  text-decoration: none;
  color: white;
}

a {
  color: white;
}

a:hover {
  color: white;
}

label {
  color: white;
}

</style>