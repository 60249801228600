import api from './index'
import interfaces from "../constant/interfaces";

export function getSignFile(){
    return api.get(`${interfaces.GET_INTERFACE(interfaces.GET_SIGN_FILE)}`)
}
/**
 * 获取实名认证url
 */
export function realName(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.REAL_NAME)}`, data)
}

/**
 * 获取实名认证url
 */
export function legalPersonRealName(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.LEGAL_PERSON_REAL_NAME)}`, data)
}


/**
 * 绑定用户邮箱
 * @param data data
 */
export function bindUserEmail(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.CHANGE_EMAIL)}`, data)
}

/**
 * 修改用户邮箱
 * @param data data
 */
export function changeUserEmail(data) {
    return api.put(`${interfaces.GET_INTERFACE(interfaces.CHANGE_EMAIL)}`, data)
}

/**
 * 修改用户交易密码
 * @param data data
 */
export function changeUserTransactionPassword(data) {
    return api.put(`${interfaces.GET_INTERFACE(interfaces.CHANGE_TRANSACTION_PASSWORD)}`, data)
}

/**
 * 修改用户登录密码
 * @param data data
 */
export function changeUserPassword(data) {
    return api.put(`${interfaces.GET_INTERFACE(interfaces.CHANGE_PASSWORD)}`, data)
}

/**
 * 删除用户银行卡
 */
export function deleteUserCardById(id) {
    return api.delete(`${interfaces.GET_INTERFACE(interfaces.DELETE_USER_CARD)}/${id}`)

}

/**
 * 获取用户所有银行卡
 */
export function getUserAllCards() {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.USER_ALL_CARDS)}`)

}

/**
 * 添加银行卡
 * @param data data
 */
export function addCard(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.ADD_CARD)}`, data)
}

/**
 * 绑定手机
 * @param data
 * @returns {AxiosPromise<any>}
 */
export function bindPhone(data) {
    return api.put(`${interfaces.GET_INTERFACE(interfaces.BIND_PHONE)}`, data)
}

/**
 * 是否存在手机号
 * @param phone
 */
export function existPhone(phone) {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.EXIST_PHONE)}`, {
        params: {
            phone
        }
    })
}

/**
 * 修改手机号
 * @param data data
 */
export function changePhone(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.CHANGE_PHONE)}`, data)
}

/**
 * 修改手机号发送验证码
 */
export function sendOrignPhoneCode() {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.CHANGE_PHONE_CODE)}`)
}

/**
 * 发送新手机号码
 * @param phone
 * @returns {AxiosPromise<any>}
 */
export function sendNewPhoneCode(phone) {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.CHANGE_PHONE_NEW_CODE)}`, {
        params: {
            phone
        }
    })
}

/**
 * 修改QQ
 * @param nickname
 */
export function changeQQ(qq) {
    return api.put(`${interfaces.GET_INTERFACE(interfaces.CHANGE_QQ)}`, {qq})
}

/**
 * 修改昵称
 * @param nickname
 */
export function changeNickName(nickname) {
    return api.put(`${interfaces.GET_INTERFACE(interfaces.CHANGE_NICKNAME)}`, {nickname})
}

/**
 * 退出登录
 */
export function signOut() {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.SIGN_OUT)}`)
}

/**
 * 当前用户详情
 */
export function userInfo() {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.UER_INFO)}`)
}

/**
 * 账号登录
 * @param data
 */
export function accountSignIn(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.SIGN_IN_ACCOUNT)}`, data)
}

/**
 * 手机验证码登录
 * @param data
 */
export function phoneSignIn(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.SIGN_IN_PHONE)}`, data)
}

/**
 * 登录验证码
 * @param data
 */
export function signInCode(phone) {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.SIGN_IN_CODE)}`, {
        params: {
            phone
        }
    })
}


/**
 * 注册
 * @param data 登录信息
 */
export function signUp(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.SIGN_UP)}`, data)
}

/**
 * 发送短信验证码
 * @param phone
 * @returns {AxiosPromise<any>}
 */
export function getSignUpCode(phone) {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.SIGN_UP_CODE)}`, {
        params: {
            phone
        }
    })
}

/**
 * 发送邮箱验证码
 * @param email 邮箱号
 * @returns {AxiosPromise<any>}
 */
export function getEmailCode(email) {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.MAIL_CODE)}`, {
        params: {
            email
        }
    })
}

/**
 * 修改企业认证
 * @param data data
 */
export function changeCompanyInfo(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.CHANGE_COMPANY_INFO)}`, data)
}

/**
 * 查询企业认证信息
 * @param data data
 */
export function queryCompanyInfo() {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.QUERY_COMPANY_INFO)}`)
}



