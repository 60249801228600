import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home";
import Index from "@/views/Index"
import SignUp from "@/views/SignUp";
import SignIn from "@/views/SignIn";
import PersonalCenter from "@/views/personalcenter/Index"
import AccountInfo from "@/views/personalcenter/accountinfo/Index"
import AccountTransactionRecord from '@/views/personalcenter/transactionrecord/Index'
import WithDraw from '@/views/personalcenter/withdraw/Index'
import WithDrawRecord from '@/views/personalcenter/withdraw/Record'
import BaseManage from  '@/views/personalcenter/basemanage/Index'
import SellCard from '@/views/sellcard/Index'
import WebChat from '@/views/webchat/Index'
import AboutUs from '@/views/aboutus/Index'
import ContactUs from '@/views/contactus/Index'
import AllOrder from "@/views/personalcenter/order/AllOrder";
import OrderStat from "@/views/personalcenter/order/OrderStat";
import BatchOrder from "@/views/personalcenter/order/BatchOrder";
import BatchOrderInfo from "@/views/personalcenter/order/BatchOrderInfo";
import OrderInfo from "@/views/personalcenter/order/OrderInfo";
import Help from '@/views/help/Index'
import HelpInfo from "@/views/help/Info";


import store from '../store/index'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)



const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        redirect: '/home',
        children: [
            {path: '/home', component: Home},
            {path: '/sellCard', component: SellCard},
            {path: '/webchat', component: WebChat},
            {path: '/contactus', component: ContactUs},
            {path: '/aboutus', component: AboutUs},
            {path: '/help', component: Help},
            {path: '/helpInfo', component: HelpInfo},
            {
                path: '/center',
                component: PersonalCenter,
                children: [
                    {path: '/center/accountinfo', component: AccountInfo},
                    {path: '/center/transactionRecord', component: AccountTransactionRecord},
                    {path: '/center/withdraw', component: WithDraw},
                    {path: '/center/withdraw/record', component: WithDrawRecord},
                    {path: '/center/baseManage', component: BaseManage},
                    {path: '/center/order/allOrder', component: AllOrder},
                    {path: '/center/order/batchOrder', component: BatchOrder},
                    {path: '/center/order/batchOrderInfo', component: BatchOrderInfo},
                    {path: '/center/order/orderInfo', component: OrderInfo},
                    {path: '/center/order/OrderStat', component: OrderStat},

                ]
            },
        ]
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp,
    },
    {
        path: '/signin',
        name: 'SignIn',
        component: SignIn,
    },
]

const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path.startsWith("/center")) {
        if (!store.getters["user/isLogin"]) {
            store.commit('user/getUserInfo', {
                callback: () => {
                    next()
                }, failCallBack: () => {
                    next("/signin")
                }
            })
        } else {
            next()
        }

    } else {
        next()
    }


    // ...
})
export default router
