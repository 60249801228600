<template>
  <div class="sell-card-container">
    <div data-v-e6981518="" class="wechat-banner">
      <div data-v-e6981518="" class="w1320">
        <div data-v-e6981518="" class="wx-qrcode-box">
          <img data-v-e6981518="" src="~@/assets/image/oyqrcode.jpg" alt="" class="qrcode-img">
        </div>

        
      </div>
    </div>
    <!--  -->

    <div data-v-e6981518="" class="public-contern">
      <div data-v-e6981518="" class="w1320">
        <div data-v-e6981518="" class="public-main">
        <span data-v-e6981518="" class="public-contern-img fr"><img data-v-e6981518="" src="~@/assets/image/narrate01.e4f4fbc.png" alt="" /></span> 
        <span data-v-e6981518="" class="public-contern-date fl"><img data-v-e6981518="" src="~@/assets/image/duihuan01.png" alt="" /></span>
        </div> 
        <div data-v-e6981518="" class="public-main">
        <span data-v-e6981518="" class="public-contern-date fr"><img data-v-e6981518="" src="~@/assets/image/huishou02.png" alt="" /></span> 
        <span data-v-e6981518="" class="public-contern-img fl"><img data-v-e6981518="" src="~@/assets/image/narrate02.10a7814.png" alt="" /></span>
        </div> 
        <div data-v-e6981518="" class="public-main">
        <span data-v-e6981518="" class="public-contern-img fr"><img data-v-e6981518="" src="~@/assets/image/narrate03.c2de2b5.png" alt="" /></span> 
        <span data-v-e6981518="" class="public-contern-date fl"><img data-v-e6981518="" src="~@/assets/image/tikuan03.png" alt="" /></span>
        </div> 
        <div data-v-e6981518="" class="public-main">
        <span data-v-e6981518="" class="public-contern-date fr"><img data-v-e6981518="" src="~@/assets/image/zhonglei04.png" alt="" /></span> 
        <span data-v-e6981518="" class="public-contern-img fl"><img data-v-e6981518="" src="~@/assets/image/narrate04.4f9c36c.png" alt="" /></span>
        </div>
      </div>
    </div>
      
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Index",
  data() {
    return {
      customRate: 0,//供货折扣
      isSingleSubmit: true,//是否单笔订单
      currentCardPriceId: 0,//当前面值id
      singeSubmitCard: {
        account: "",
        password: "",
      },
      multiSubmiteCardList: [],
      isReadProtocol: false,
      isAccessProtocol: false,
      discount: "",
      currentCardTypeId: 0,
      currentCardId: 0,
      cardTypeList: [],
      currentCardList: [],
      currentCardPriceList: [],
      cardTypeId: ""
    }
  },
  components:{
    Footer
  },
  created() {
    console.log(this.$store.getters['user/isLogin'])
    this.getCardTypes()
    // this.cardTypeId = this.$route.query.cardTypeId;
  },

  mounted() {
    // console.log("=============" + this.cardTypeId)
    // this.selectCardType(this.cardTypeId)
  },
  watch:{
    // "$route": "monitorAPP"
    $route: {
      handler:function(){
        this.$nextTick(function(){  //页面加载完成后执行
          // console.log("=============" + val+oldVal)
          this.selectCardType(this.$route.query.cardTypeId)
        })
      },
      // 深度观察监听
      deep: true
    }
  },
  computed: {
    //当前面值
    currentCardPrice() {
      if (this.currentCardPriceId == 0) {
        return {}
      }
      return this.currentCardPriceList.filter(value => value.id === this.currentCardPriceId)[0]
    },
    //当前选择卡种
    currentCard() {
      if (this.currentCardId == 0) {
        return {}
      }
      return this.currentCardList.filter(value => value.id === this.currentCardId)[0]
    },
    currentCarmi() {
      let carmiRule = this.currentCard.carmiRule;
      if (carmiRule == null) {
        return {}
      }
      return {
        accountLen: carmiRule.split(":")[0],
        passwordLen: carmiRule.split(":")[1]
      }
    }
  },

  methods: {
    

  }
}
</script>

<style scoped>
.wechat-banner[data-v-e6981518] {
    position: relative;
    width: 100%;
    height: 476px;
    background: url(~@/assets/image/webchat_bg.png) no-repeat center 0;
}
   
.public-contern[data-v-e6981518] {
    width: 100%;
    background: #fff;
    overflow: hidden;
}

.wechat-banner .w1320[data-v-e6981518] {
    position: relative;
}

.w1320 {
    width: 1320px;
    margin: 0 auto;
}

.public-main[data-v-e6981518] {
    overflow: hidden;
    margin: 30px 152px;
}

.public-main .public-contern-img[data-v-e6981518] {
    width: 406px;
    height: 405px;
}
.public-main span[data-v-e6981518] {
    display: block;
}
.fr {
    float: right;
}

.public-main .public-contern-date[data-v-e6981518] {
    width: 482px;
    height: 101px;
    padding-top: 120px;
}

.wechat-banner .wx-qrcode-box[data-v-e6981518] {
    width: 254px;
    height: 254px;
    position: absolute;
    top: 90px;
    left: 642px;
}

.wechat-banner .wx-qrcode-box .qrcode-img[data-v-e6981518] {
    width: 254px;
    height: 254px;
}

</style>