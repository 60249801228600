<template>
  <div>
    <div class="item">
      <div style="font-size: 80px;line-height: 150px;margin-left: 20px;color: #FDCE0A"><a-icon type="user" /></div>
      <div class="content">
        <div style="font-size: 25px;margin-top: 25px;font-weight: bold">实名认证  <a-tag  v-if="this.$store.state.user.userInfo.realNameStatus==2" color="green">
          认证成功
        </a-tag></div>
        <div  v-if="this.$store.state.user.userInfo.realNameStatus==0">
          <div style="color: #EB5D56;margin-top: 5px">您还未完成实名认证，认证后才可销卡和提现</div>
          <div style="color: #A1A1A1;margin-top: 5px">如有疑问，请咨询客服</div>
        </div >
        <div v-if="this.$store.state.user.userInfo.realNameStatus!=0">
          <div style="color: #A1A1A1;margin-top: 5px">姓名:{{this.$store.state.user.userInfo.realName}}</div>
          <div style="color: #A1A1A1;margin-top: 5px">证件号码:{{this.$store.state.user.userInfo.idCard}}</div>
        </div>

      </div>
      <div v-if="this.$store.state.user.userInfo.realNameStatus!=2" style="height: 100%;padding-top: 52px;position: absolute;right: 25px">
        <a-button @click="showRealName" icon="idcard" type="primary" size="large">
          立即实名认证
        </a-button>
      </div>
    </div>

    <div class="item">
      <div style="font-size: 80px;line-height: 150px;margin-left: 20px;color: #FDCE0A"><a-icon type="user" /></div>
      <div class="content">
        <div style="font-size: 25px;margin-top: 25px;font-weight: bold">电子合同 <a-tag  v-if="this.$store.state.user.userInfo.realNameStatus==2" color="green">
          签署成功
        </a-tag></div>

        <div v-if="this.$store.state.user.userInfo.realNameStatus!=2">
          <div style="color: #EB5D56;margin-top: 5px">为保障您的合法权益，请及时签署承诺书</div>
          <div style="color: #A1A1A1;margin-top: 5px">如有疑问，请咨询客服</div>
        </div>
        <div v-else>
          <div style="color: #A1A1A1;margin-top: 5px">您当前已签署承诺书</div>
          <div style="color: #A1A1A1;margin-top: 5px">详情了解可查看电子合同</div>
        </div>
      </div>
      <div v-if="this.$store.state.user.userInfo.realNameStatus!=2" style="height: 100%;padding-top: 52px;position: absolute;right: 25px">
        <a-button  @click="showRealName" icon="idcard" type="primary" size="large">
          立即签署合同
        </a-button>
      </div>
      <div v-else style="height: 100%;padding-top: 52px;position: absolute;right: 25px">
        <a-button :loading="watchSignFileloading"  @click="getSignFile" icon="idcard" type="primary" size="large">
          查看电子合同
        </a-button>
      </div>
    </div>
    <el-dialog
        title="实名认证"
        :visible.sync="centerDialogVisible"
        width="50%"
        center>
      <img style="display: block;margin: 0 auto;" src="../assets/image/contract-icon.1ed85de.png" >
      <div style="margin: 0 auto;font-size: 22px;text-align: center;color: #3F3F3F">为保障您的合法权益，请签署承诺书</div>
      <div style="width: 355px;margin: 0 auto;font-size: 16px;color: #B8B8B8;line-height: 28px;margin-top: 5px">为有效保障您的合法权益和173销卡正常合法经营
        需实名认证并签署承诺书，才能使用173销卡回收
        服务及提现操作，请您仔细阅读并签署！</div>
      <div style="width: 484px;margin: 0 auto;color:#8FC431;font-size: 12px;margin-top: 10px">
        *请输入与您银行卡、微信、支付宝一致的姓名和身份证号
      </div>
      <div style="width: 484px;margin: 0 auto">
        <a-input v-model="realNameForm.name" placeholder="请输入与身份证一致的真实姓名" />
      </div>
      <div style="width: 484px;margin: 0 auto;font-size: 12px;color: #E79F5E;margin-top: 10px">
        *提现也必须是这个身份证所开的银行卡、微信、支付宝，请注意填写
      </div>
      <div style="width: 484px;margin: 0 auto">
        <a-input v-model="realNameForm.idCard" placeholder="请输入您的身份证号码" />
      </div>


      <span slot="footer" class="dialog-footer">
    <el-button @click="realName" type="primary" :loading="submitBtnLoading" >立即去签署</el-button>
  </span>
    </el-dialog>

    <el-dialog
        title="实名认证"
        :visible.sync="realNameDoingDialogVisible"
        width="50%"
        center>
      <img style="display: block;margin: 0 auto;" src="../assets/image/contract-icon.1ed85de.png" >
      <div style="margin: 0 auto;font-size: 22px;text-align: center;color: #3F3F3F">为保障您的合法权益，请签署承诺书</div>
      <div style="width: 355px;margin: 0 auto;font-size: 16px;color: #B8B8B8;line-height: 28px;margin-top: 5px">为有效保障您的合法权益和173销卡正常合法经营
        需实名认证并签署承诺书，才能使用173销卡回收
        服务及提现操作，请您仔细阅读并签署！</div>
      <div style="width: 350px;margin: 0 auto;color:#8FC431;font-size: 12px;margin-top: 10px">
       真实姓名
      </div>
      <div style="width: 350px;margin: 0 auto">
        <span>{{realNameForm.name}}</span>
      </div>
      <div style="width: 350px;margin: 0 auto;font-size: 12px;color: #E79F5E;margin-top: 10px">
        身份证号
      </div>
      <div style="width: 350px;margin: 0 auto">
        <span>{{realNameForm.idCard}}</span>
      </div>


      <span slot="footer" class="dialog-footer">
    <el-button @click="realName" type="primary" :loading="submitBtnLoading" >立即去签署</el-button>
  </span>
    </el-dialog>

    <el-dialog @close="closeQr"
        title="签署承诺书"
        :visible.sync="qrCodeVisible"
        width="30%"
        center>
      <div style="width: 100%">
       <div style="display: flex;justify-content: center"> <vue-qr style="margin: 0 auto" :correctLevel="3" :autoColor="false"  :text="realNameUrl" :size="300" ></vue-qr></div>
      </div>
      <div style="text-align: center">微信扫码签署协议</div>
    </el-dialog>


  </div>

</template>

<script>
import {isIdcard} from '@/utils/reg'
import {realName,getSignFile} from '@/api/customer'
import VueQr from 'vue-qr'

export default {
  name: "RealNameTab",
  components:{
    VueQr
  },
  data(){
    return {
      watchSignFileloading: false,
      submitBtnLoading:false,
      codeUrl: 'https://github.com/lx719679895',
      qrCodeVisible: false,
      realNameUrl:"",
      centerDialogVisible: false,
      realNameDoingDialogVisible: false,
      realNameForm:{
        name:"",
        idCard:""
      }
    };
  },
  methods:{
    getSignFile(){
      this.watchSignFileloading = true
      getSignFile()
      .then(res=>{
        window.open(res.data.data.url)
      }).finally(()=>{
        this.watchSignFileloading = false
      })

    },
    closeQr(){
      this.$store.commit("user/getUserInfo")
    },
    showRealName(){
      if (this.$store.state.user.userInfo.realNameStatus === 1){
        this.realNameForm.name = this.$store.state.user.userInfo.realName
        this.realNameForm.idCard = this.$store.state.user.userInfo.idCard
        this.realNameDoingDialogVisible = true
      }else {
        this.centerDialogVisible = true

      }
    },

    realName(){
      this.submitBtnLoading = true
      if (!isIdcard(this.realNameForm.idCard)){
        this.$message.error("请输入正确格式身份证")
        return
      }
      realName(this.realNameForm)
      .then(res => {
        console.log(res)
        this.realNameUrl = res.data.data.realNameUrl
        this.centerDialogVisible = false
        this.realNameDoingDialogVisible = false
        this.qrCodeVisible = true
        this.submitBtnLoading = false
      })

    }
  }
}
</script>

<style scoped>
.item{
  width: 750px;
  height: 150px;
  background-color: #F3F7FF;
  margin: 30px auto;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
}
.content{
  height: 100%;
  text-align: left;
  margin-left: 10px;
  font-size: 14px;
}

</style>