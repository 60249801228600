<template>
  <div style="background-color:white;height: 800px;overflow: scroll">
    <div
      style="height: 100px;line-height: 100px;font-size: 20px;text-align: left;padding-left: 10px"
    >批量订单</div>
    <div class="search-from">
      <a-row>
        <a-col :span="3" style="line-height: 32px">时间查询:</a-col>
        <a-col :span="5">
          <a-range-picker
            style="width: 100%"
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm:ss"
            :placeholder="['开始时间', '结束时间']"
            @change="onChange"
            @ok="onOk"
          />
        </a-col>
        <a-col :span="3" style="line-height: 32px">订单查询:</a-col>
        <a-col :span="5">
          <a-input style="width: 100%;" v-model="searchForm.orderId" placeholder="请输入订单号查询" />
        </a-col>
        <a-col :offset="1" :span="1">
          <a-button @click="search" type="primary">查询</a-button>
        </a-col>
      </a-row>
    </div>

    <div style="width: 100%;margin-top: 10px">
      <a-table
        bordered
        :table-layout="fixed"
        :rowKey="(record)=>record.orderId"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :size="small"
      >
        <span slot="status" slot-scope="status">
          <el-tag v-if="status == '2'" type="danger" effect="dark">
            {{ orderStatus[status] }} 
          </el-tag>
          <el-tag v-if="status == '1'" type="success" effect="dark">
            {{ orderStatus[status] }} 
          </el-tag>
          <el-tag v-if="status == '0'" type="info" effect="dark">
            {{ orderStatus[status] }} 
          </el-tag>
        </span>
        <span slot="orderType">批量订单</span>
        <span slot="allPrice" slot-scope="record">{{ record.price * record.commitCount }}</span>
        <span slot="realGet" slot-scope="record">{{ record.allFinalPrice * record.userRate / 100 }}</span>
        <span slot="action" slot-scope="text, record">
          <!-- <a-button v-if="record.failCount == 0 || record.status == 2 || record.failCount > 0" type="primary" @click="failSubmit(record)">失败提交</a-button> -->
          <a-button type="danger" @click="showDialog(record)">修改折扣</a-button>
          <a-button type="waring" @click="gotoBatchInfo(record.orderId)">查看详情</a-button>
        </span>
      </a-table>
    </div>

    <el-dialog title="修改折扣" :visible.sync="dialogFormVisible">
      <div class="search-from">
        <a-row>
          <a-col :span="3" style="line-height: 32px">批量订单号:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="mainForm.orderId" readOnly=true />
          </a-col>
          <a-col :span="3" style="line-height: 32px">卡种:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="mainForm.cardName" readOnly=true />
          </a-col>
        </a-row>

        <a-row style="margin-top: 20px;">
          <a-col :span="3" style="line-height: 32px">提交总面值:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="mainForm.allPrice" readOnly=true />
          </a-col>
          <a-col :span="3" style="line-height: 32px">现有折扣:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="mainForm.userRate" readOnly=true />
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px;">
          <a-col :span="3" style="line-height: 32px">修改折扣:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="order.userMoneyRate" oninput="value=value.replace(/[^\d.]/g,'')" placeholder="修改折扣不能高于现有折扣" />
          </a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :offset="3" :span="1">
            <a-button @click="changeRate" type="primary">保存</a-button>
          </a-col>
           <a-col :offset="3" :span="1">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
          </a-col>
        </a-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import emuns from "@/constant/emuns";
import orderApi from "@/api/order";

const columns = [
  {
    title: "日期",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "200"
  },
  {
    title: "订单id",
    dataIndex: "orderId",
    scopedSlots: { customRender: "orderId" },
    width: "200"
  },
  {
    title: "卡种",
    dataIndex: "cardName",
    scopedSlots: { customRender: "cardName" },
    width: "200"
  },
  {
    title: "提交总面值",
    scopedSlots: { customRender: "allPrice" },
    width: "200"
  },
  {
    title: "实际总面值",
    dataIndex: "allFinalPrice",
    scopedSlots: { customRender: "allFinalPrice" },
    width: "200"
  },
  {
    title: "实际可得",
    scopedSlots: { customRender: "realGet" }
  },
  {
    title: "总共提交",
    dataIndex: "commitCount",
    scopedSlots: { customRender: "commitCount" }
  },
  {
    title: "成功销卡",
    dataIndex: "successCount",
    scopedSlots: { customRender: "successCount" }
  },
  {
    title: "销卡失败",
    dataIndex: "failCount",
    scopedSlots: { customRender: "failCount" }
  },

  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" }
  },
  {
    title: "提交方式",
    scopedSlots: { customRender: "orderType" }
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "Index",
  data() {
    return {
      dialogFormVisible: false,
      mainForm: {},
      order: {},
      orderStatus: emuns.orderStatus,
      type: emuns.orderType,
      data: [],
      loading: false,
      searchForm: {
        createTimeBegin: null,
        createTimeEnd: null,
        orderId: ""
      },
      columns,
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        pageSize: 10,
        showSizeChanger: true
      }
    };
  },
  created() {
    this.loadData();
  },
  mounted() {},
  methods: {
    showDialog(row) {
      this.mainForm = row;
      this.mainForm.allPrice = row.price * row.commitCount 
      this.order.orderId = row.orderId;
      this.order.id = row.id;
      this.order.orderType = 1; // 批量卡
      this.order.userMoneyRate = "";
      this.dialogFormVisible = true;
    },
    changeRate() {
      if(this.order.userMoneyRate > this.mainForm.userRate){
        this.$message.error("修改折扣不能超过现有折扣")
        return;
      }
      orderApi.changeRate(this.order).then(res => {
        if(res.code == 200){
          this.dialogFormVisible = false
          this.$message.success("保存成功")
          this.loadData();
        }
        
      });
    },
    failSubmit(row) {
      row.orderType = 1; //批量卡
      orderApi.failSubmit(row).then(res => {
        if(res.code == 200){
          this.$message.success("提交成功")
          this.loadData()
        }
        
      });
    },
    gotoBatchInfo(batchOrderId) {
      this.$router.push({
        path: "/center/order/batchOrderInfo",
        query: {
          batchOrderId: batchOrderId
        }
      });
    },
    search() {
      this.pagination.current = 1;
      this.loadData();
    },
    loadData() {
      this.loading = true;
      orderApi.searchAllBatchOrder({
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          createTimeBegin: this.searchForm.createTimeBegin,
          createTimeEnd: this.searchForm.createTimeEnd,
          orderId: this.searchForm.orderId
        })
        .then(res => {
          this.data = res.data.data.data;
          this.loading = false;
          this.pagination.total = res.data.data.total;
        });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    fetch(params = {}) {
      console.log("params:", params);
    },
    searchOrder() {
      console.log(this.searchForm);
    },
    onChange(value, dateString) {
      this.searchForm.createTimeBegin = dateString[0];
      this.searchForm.createTimeEnd = dateString[1];
    },
    onOk(value) {
      this.searchForm.createTimeBegin = value[0].format("YYYY-MM-DD HH:mm:ss");
      this.searchForm.createTimeEnd = value[0].format("YYYY-MM-DD HH:mm:ss");
    },
    handleChange(value) {
      this.searchForm.type = value;
    },
    handleStatusChange(value) {
      this.searchForm.status = value;
    }
  }
};
</script>

<style scoped>
.ant-table-thead > tr > th{
white-space:nowrap;
}
.ant-table-row td{
white-space:nowrap;
}
</style>