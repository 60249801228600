<template>
  <div class="phone-setting-container">
    <div style="height: 100%;width: 100%;">
      <div style="width: 500px;margin: 0 auto">
        <a-tabs :activeKey="currentKey" @change="changeTab">

          <a-tab-pane key="1" tab="登录密码管理" :activeKey="currentKey" @change="changeTab">
            <a-form-model ref="passwordForm" :model="passwordRuleForm" :rules="passwordRuleForm" v-bind="layout">
              <a-form-model-item label="原登录密码" prop="originPassword">
                <a-input v-model="passwordRuleForm.originPassword" type="password" autocomplete="off"/>
              </a-form-model-item>
              <a-form-model-item label="新登录密码" prop="newPassword">
                <a-input v-model="passwordRuleForm.newPassword" type="password" autocomplete="off"/>
              </a-form-model-item>
              <a-form-model-item label="确认新密码" prop="confirmNewPassword">
                <a-input v-model="passwordRuleForm.confirmNewPassword" type="password" autocomplete="off"/>
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="submitForm">
                  保存
                </a-button>
                <a-button style="margin-left: 10px" @click="resetForm('passwordForm')">
                  重置
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </a-tab-pane>

          <a-tab-pane key="2" tab="交易密码管理" force-render>
            <a-form-model ref="transactionPasswordForm" :model="transactionPasswordForm" v-bind="layout">
              <a-form-model-item label="交易密码">
                <a-input v-model="transactionPasswordForm.transactionPassword" type="password" autocomplete="off"/>
              </a-form-model-item>
              <a-form-model-item label="手机/邮箱">
                <a-select style="width: 290px" @change="phoneMailSelectorChange">
                  <a-select-option v-if="this.$store.state.user.userInfo.phone!=null" value="0">
                    {{ this.$store.state.user.userInfo.phone }}
                  </a-select-option>
                  <a-select-option v-if="this.$store.state.user.userInfo.email!=null" value="1">
                    {{ this.$store.state.user.userInfo.email }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="验证码">
                <a-row>
                  <a-col :span="14">
                    <a-input v-model="transactionPasswordForm.code" type="password" autocomplete="off"/>
                  </a-col>
                  <a-col :offset="1" :span="6">
                    <CodeButton @click="sendCode"></CodeButton>
                  </a-col>
                </a-row>
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="submitTransactionPasswordForm">
                  保存
                </a-button>
                <a-button style="margin-left: 10px" @click="resetForm('transactionPasswordForm')">
                  重置
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </a-tab-pane>
        </a-tabs>
      </div>
      <a-modal
          title="修改登录密码"
          :visible="changePasswordVisible"
          @ok="handleChangePasswordOk"
          @cancel="closeChangePasswordAlert"
      >
        <p>您确定修改登录密码吗?</p>
      </a-modal>
      <a-modal
          title="设置交易密码"
          :visible="changeTransactionPasswordVisible"
          @ok="handleChangeTransactionPasswordOk"
          @cancel="closeChangeTransactionPasswordAlert"
      >
        <p>确认修改吗?</p>
      </a-modal>

    </div>
  </div>
</template>

<script>

import {isPasswordAvailable} from "@/utils/reg";
import {changeUserPassword, changeUserTransactionPassword, getSignUpCode, getEmailCode} from "@/api/customer"
import CodeButton from "@/components/CodeButton";

export default {
  name: "PasswordManageTab",
  components: {
    CodeButton
  },
  data() {
    let validatePassword = (rule, value, callback) => {
      console.log(value)
      if (!isPasswordAvailable(value)) {
        callback(new Error('密码由6-20位字母、数字或者符号两种或以上组成'));
      }
      callback();
    };
    let validatePasswordConfirm = (rule, value, callback) => {
      if (!isPasswordAvailable(value)) {
        callback(new Error('密码由6-20位字母、数字或者符号两种或以上组成'));
      }
      if (this.passwordRuleForm.newPassword !== this.passwordRuleForm.confirmNewPassword) {
        callback(new Error("密码不一致!"))
      }
      callback();
    };
    return {
      currentKey:"1",
      changeTransactionPasswordVisible: false,
      changePasswordVisible: false,
      passwordRuleForm: {
        originPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      passwordFormRules: {
        originPassword: [{required: true, validator: validatePassword, trigger: 'change'}],
        newPassword: [{required: true, validator: validatePassword, trigger: 'change'}],
        confirmNewPassword: [{required: true, validator: validatePasswordConfirm, trigger: 'change'}],
      },
      transactionPasswordForm: {
        transactionPassword: "",
        type: "",
        code: "",
      },
      transactionPasswordFormRules: {},
      layout: {
        labelCol: {span: 4},
        wrapperCol: {span: 14},
      },
    };
  },
  created() {
    let tab = this.$route.query.passwordTab;
    console.log(tab)
    if (tab !== undefined) {
      this.currentKey = tab;
    }
  },
  methods: {

    changeTab(key){
      console.log(key)
      this.$router.replace(`/center/baseManage?tab=passwordManage&passwordTab=${key}`)
      this.currentKey = key
    },
    handleChangeTransactionPasswordOk() {
      changeUserTransactionPassword(this.transactionPasswordForm)
          .then(() => {
            this.$message.success("修改成功")
          })
          .finally(() => {
            this.closeChangeTransactionPasswordAlert();
          })
    },
    sendCode(callback) {
      if (this.transactionPasswordForm.type === "") {
        callback(false)
        this.$message.error("选择验证类型")
        return
      }
      if (this.transactionPasswordForm.type === "0") {
        //手机验证码
        getSignUpCode(this.$store.state.user.userInfo.phone)
      }
      if (this.transactionPasswordForm.type === "1") {
        //邮箱验证码
        getEmailCode(this.$store.state.user.userInfo.email)
      }
      callback(true)
    },
    phoneMailSelectorChange(value) {
      this.transactionPasswordForm.type = value
    },
    handleChangePasswordOk() {
      changeUserPassword(this.passwordRuleForm)
          .then(() => {
            this.$message.success("修改成功,请重新登录")
            this.closeChangePasswordAlert()
            this.$store.commit("user/signOut")
          })
          .catch(() => {
            this.closeChangePasswordAlert()
          })
    },
    openChangeTransactionPasswordAlert() {
      this.changeTransactionPasswordVisible = true
    },
    openChangePasswordAlert() {
      this.changePasswordVisible = true
    },
    closeChangePasswordAlert() {
      this.changePasswordVisible = false
    },
    closeChangeTransactionPasswordAlert() {
      this.changeTransactionPasswordVisible = false
    },
    submitForm() {
      if (this.passwordRuleForm.originPassword === "" || this.passwordRuleForm.newPassword === "" || this.passwordRuleForm.confirmNewPassword === "") {
        this.$message.error("请填写完整信息")
        return
      }
      if (this.passwordRuleForm.confirmNewPassword !== this.passwordRuleForm.newPassword) {
        this.$message.error("两次输入密码不一致!")
        return
      }
      this.openChangePasswordAlert()
    },
    submitTransactionPasswordForm() {
      if (this.transactionPasswordForm.transactionPassword == "") {
        this.$message.error("交易密码未填写")
        return
      }
      if (this.transactionPasswordForm.code == "") {
        this.$message.error("验证码未填写")
        return
      }
      this.openChangeTransactionPasswordAlert()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
}
</script>

<style scoped>


.phone-setting-container {
  padding: 60px 20px 20px;
  height: 600px;
}

</style>