<template>
  <div class="help-info-container">
    <div style="position: relative;left: 30px;width: 400px;margin-top: 20px">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link to="/">首页</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="gotoArticleList">{{ info.categoryName }}</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item><a href="javascript:void(0)">{{ info.title }}</a></a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div style="width: 100%;background-color:#FFFFFF;box-shadow: 0 15px 30px gainsboro;margin-top: 20px;padding-left: 20px;
  padding-right: 20px;padding-bottom: 20px">
      <div class="title">
        {{ info.title }}
      </div>
      <div class="article-info">
        <div>来源：{{ info.author }}</div>
        <div>分类：{{ info.categoryName }}</div>
        <div>发布时间：{{ info.updateTime }}</div>
      </div>
      <a-divider/>

      <div class="content ql-editor" style="text-align:left" v-html="info.content">
      </div>

      <div class="action">
        <div @click="gotoArticle(info.beforeArticleId)" v-if="info.beforeArticleId!=null" style="color: #1890ff;"
             class="before">
          <a-icon type="left"/>
          {{ info.beforeArticleTitle }}
        </div>
        <div v-else style="color: #999999;cursor: auto" class="before">
          <a-icon type="left"/>
          没有了
        </div>

        <div @click="gotoArticle(info.nextArticleId)" v-if="info.nextArticleId!=null" style="color: #1890ff;"
             class="after">
          <a-icon type="right"/>
          {{ info.nextArticleTitle }}
        </div>
        <div v-else style="color: #999999;cursor: auto" class="after">
          <a-icon type="right"/>
          没有了
        </div>

      </div>

    </div>
  </div>

</template>

<script>
import helpApi from '@/api/help'

export default {
  name: "Info",
  created() {
    this.id = this.$route.query.id;
    this.categoryId = this.$route.query.categoryId;
    this.getInfo()
  },
  data() {
    return {
      id: 0,
      categoryId: 0,
      content: "<h1>xxx</h1>",
      info: {}
    }
  },
  methods: {
    getInfo() {
      helpApi.articleInfo(this.id).then(res => {
        this.info = res.data.data
      })
    },
    gotoArticle(id) {
      //this.$router.replace("/helpInfo?id=" + id)
      this.$router.push({
        path: "/helpInfo",
        query: {
          id
        },
        replace:true
      })
      this.id = id;
      this.getInfo()


    },
    gotoArticleList() {
      this.$router.push({
        path: "/help",
        query: {
          categoryId: this.categoryId
        }
      })
    }
  }
}
</script>

<style scoped>
.before {
  cursor: pointer;
}

.after {
  cursor: pointer;
}

.action {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
}

.content {
  width: 100%;
  min-height: 100px;
}

.article-info {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  width: 800px;
  margin: 5px auto 0;
  color: #999999;
}

.title {
  text-align: center;
  font-size: 24px;
  color: #1890ff;
}

.help-info-container {
  width: 1320px;
  margin: 0 auto;
  min-height: 200px;
  position: relative;

}
</style>