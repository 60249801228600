<template>
  <div style="background-color:white;height: 800px;overflow: scroll">
    <div style="height: 100px;line-height: 100px;font-size: 20px;text-align: left;padding-left: 10px">全部订单
      <span style="font-size:15px">&nbsp;&nbsp;&nbsp;&nbsp;总订单数：{{orderStatsInfo.total_cnt}} | 成功订单：{{orderStatsInfo.success_cnt}} | 失败订单：{{orderStatsInfo.fail_cnt}} | 成功总额度：{{orderStatsInfo.success_price}}元 | 未结算额度：{{orderStatsInfo.balance}}元 | 已结算额度：{{orderStatsInfo.success_draw_amt}}元</span>
    </div>
    <div class="search-from">
      <a-row>
        <a-col :span="2" style="line-height: 32px">时间:</a-col>
        <a-col :span="7">
          <a-range-picker 
                          style="width: 100%"
                          :show-time="{ format: 'HH:mm' }"
                          format="YYYY-MM-DD HH:mm:ss"
                          :placeholder="['开始时间', '结束时间']"
                          @change="onChange"
                          @ok="onOk"
                          :default-value="[
                            moment().add(-1,'days'),
                            moment()
                          ]"
                          
          />
        </a-col>
        <a-col :span="2" style="line-height: 32px">
          订单号:
        </a-col>
        <a-col :span="5">
          <a-input style="width: 100%;" v-model="searchForm.orderId" placeholder="请输入订单号查询"/>
        </a-col>
        <a-col :span="2" style="line-height: 32px">
          卡号/卡密:
        </a-col>
        <a-col :span="3">
          <a-input style="width: 100%;" v-model="searchForm.cardAccount" placeholder="请输入卡号/卡密 查询"/>
        </a-col>
        <a-col :offset="1" :span="1">
          <a-button @click="search" type="primary">
            查询
          </a-button>
        </a-col>
      </a-row>
    </div>

    <div style="width: 100%;margin-top: 10px">
      <a-table
          :rowKey="(record)=>record.orderId"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
          size="small"
      >

        <span slot="status" slot-scope="status">
          <el-tag v-if="status == '2'" type="danger" effect="dark">
            {{ orderStatus[status] }} 
          </el-tag>
          <el-tag v-if="status == '1'" type="success" effect="dark">
            {{ orderStatus[status] }} 
          </el-tag>
          <el-tag v-if="status == '0'" type="info" effect="dark">
            {{ orderStatus[status] }} 
          </el-tag>
        </span>
        <span slot="orderType" slot-scope="orderType">
          {{
            type[orderType]
          }}
        </span>
        <span slot="userRate" slot-scope="userRate">
          {{userRate}}折
        </span>
        <span slot="action" slot-scope="text, record">
          <!-- <a-button v-if="record.orderType == 0 && (record.status == 0 || record.status == 2)" type="primary" @click="failSubmit(record)">失败提交</a-button> -->
          <a-button v-if="record.orderType == 0" type="danger" @click="showDialog(record)">修改折扣</a-button>
          <a-button @click="gotoOrderInfo(record.orderId)">详情</a-button>
          <a-button v-if="record.apiOrderId != '' && record.apiOrderId != null " style="color:#52c41a" type="info" @click="apiCallBack(record)">发送回调</a-button>
        </span>
      </a-table>
    </div>

    <el-dialog title="修改折扣" :visible.sync="dialogFormVisible">
      <div class="search-from">
        <a-row>
          <a-col :span="3" style="line-height: 32px">批量订单号:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="mainForm.orderId" readOnly=true />
          </a-col>
          <a-col :span="3" style="line-height: 32px">卡种:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="mainForm.cardName" readOnly=true />
          </a-col>
        </a-row>

        <a-row style="margin-top: 20px;">
          <a-col :span="3" style="line-height: 32px">提交总面值:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="mainForm.cardPrice" readOnly=true />
          </a-col>
          <a-col :span="3" style="line-height: 32px">现有折扣:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="mainForm.userRate" readOnly=true />
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px;">
          <a-col :span="3" style="line-height: 32px">修改折扣:</a-col>
          <a-col :span="8">
            <a-input style="width: 100%;" v-model="order.userMoneyRate" oninput="value=value.replace(/[^\d.]/g,'')" placeholder="修改折扣不能高于现有折扣" />
          </a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :offset="3" :span="1">
            <a-button @click="changeRate" type="primary">保存</a-button>
          </a-col>
           <a-col :offset="3" :span="1">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
          </a-col>
        </a-row>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import emuns from '@/constant/emuns'
import orderApi from '@/api/order'
import moment from "moment"

const columns = [
  {
    title: '订单id',
    dataIndex: 'orderId',
    scopedSlots: {customRender: 'orderId'},
  },
  {
    title: '卡号',
    dataIndex: 'cardAccount',
    scopedSlots: {customRender: 'cardAccount'},
    width: '150px',
  },
   {
    title: '卡密',
    dataIndex: 'cardPassword',
    scopedSlots: {customRender: 'cardPassword'},
     width: '150px',
  },
  {
    title: '卡种',
    dataIndex: 'cardName',
    scopedSlots: {customRender: 'cardName'},
  },
  {
    title: '提交面值',
    dataIndex: 'cardPrice',
    scopedSlots: {customRender: 'cardPrice'},
  },
  {
    title: '实际面值',
    dataIndex: 'finalPrice',
    scopedSlots: {customRender: 'finalPrice'},
  },
  {
    title: '供货折扣',
    dataIndex: 'userRate',
    scopedSlots: {customRender: 'userRate'},
  },
  {
    title: '提交时间',
    dataIndex: 'createTime',
    scopedSlots: {customRender: 'createTime'},
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'},
  },
  {
    title: '提交方式',
    dataIndex: 'orderType',
    scopedSlots: {customRender: 'orderType'},
  },
  {
    title: '操作',
    scopedSlots: {customRender: 'action'},
  },

];

export default {
  name: "Index",
  data() {
    return {
      dialogFormVisible: false,
      mainForm: {},
      order: {},
      orderStatus: emuns.orderStatus,
      type: emuns.orderType,
      data: [],
      loading: false,
      searchForm: {
        createTimeBegin: null,
        createTimeEnd: null,
        orderId: "",
        cardAccount: "",
      },
      columns,
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        pageSize: 10,
        showSizeChanger: true
      },
      userInfo: {},
      orderStatsInfo: {},
    }
  },
  created() {
    this.getToday()
    this.userInfo = this.$store.state.user.userInfo
    console.log(this.userInfo)
    this.loadData()
  },
  mounted() {
  },
  methods: {
    moment,
    showDialog(row) {
      this.mainForm = row;
      this.mainForm.allPrice = row.price * row.commitCount 
      this.order.orderId = row.orderId;
      this.order.id = row.id;
      this.order.orderType = 0; //单卡
      this.order.userMoneyRate = "";
      this.dialogFormVisible = true;
    },
    changeRate() {
      if(this.order.userMoneyRate > this.mainForm.userRate){
        this.$message.error("修改折扣不能超过现有折扣")
        return;
      }
      orderApi.changeRate(this.order).then(res => {
        if(res.code == 200){
          this.dialogFormVisible = false
          this.$message.success("保存成功")
          this.loadData();
        }
        
      });
    },
    failSubmit(row) {
      row.orderType = 0; //单卡
      orderApi.failSubmit(row).then(res => {
        if(res.code == 200){
          this.$message.success("提交成功")
          this.loadData()
        }
      });
    },
    apiCallBack(row) {
      orderApi.apiCallBack(row).then(res => {
        if(res.code == 200){
          this.$message.success("发送成功")
          this.loadData()
        }
      });
    },
    gotoOrderInfo(orderId){
       this.$router.push({
         path:"/center/order/orderInfo",
         query:{
           orderId
         }
       })
    },
    search() {
      this.pagination.current = 1
      this.loadData()
    },
    loadData() {
      this.loading = true
      orderApi.searchAllOrder({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
        createTimeBegin: this.searchForm.createTimeBegin,
        createTimeEnd: this.searchForm.createTimeEnd,
        orderId: this.searchForm.orderId,
        cardAccount: this.searchForm.cardAccount,
      }).then(res => {
        this.data = res.data.data.data
        this.loading = false
        this.pagination.total = res.data.data.total
        this.orderStatsInfo = res.data.data.orderStats
      })
    },

    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData()

    },
    fetch(params = {}) {
      console.log('params:', params);
    },
    searchOrder() {
      console.log(this.searchForm)
    },
    onChange(value, dateString) {
      this.searchForm.createTimeBegin = dateString[0]
      this.searchForm.createTimeEnd = dateString[1]
    },
    onOk(value) {
      this.searchForm.createTimeBegin = value[0].format('YYYY-MM-DD HH:mm:ss')
      this.searchForm.createTimeEnd = value[1].format('YYYY-MM-DD HH:mm:ss')
    },
    handleChange(value) {
      this.searchForm.type = value
    },
    handleStatusChange(value) {
      this.searchForm.status = value
    },

    changeDate() {
      this.$forceUpdate()
    },

    getToday() {
      let time = new Date();
      const yy = time.getFullYear(); //获取完整的年份(4位,1970-???)
      const M = time.getMonth() + 1; //获取当前月份(0-11,0代表1月),
      const d = time.getDate(); //获取当前日(1-31)
      // const H = time.getHours(); //获取当前小时数(0-23)
      // const m = time.getMinutes(); //获取当前分钟数(0-59)
      // const s = time.getSeconds(); //获取当前秒数(0-59)
      
      // 小于9的，在前面加0
      const MM = M > 9 ? M : "0" + M;
      const dd = d > 9 ? d : "0" + d;
      // const HH = H > 9 ? H : "0" + H;
      // const mm = m > 9 ? m : "0" + m;
      // const ss = s > 9 ? s : "0" + s;
      // 指定的过去时间
      const begintime = yy + "-" + MM + "-" + dd + " 00:00:00";
      // 当前时间
      const endtime = yy + "-" + MM + "-" + dd + " 23:59:59";

      // return [begintime, endtime];
      this.searchForm.createTimeBegin = begintime;
      this.searchForm.createTimeEnd = endtime;
    },
  }
}
</script>

<style scoped>

</style>