<template>
  <div>
    <!--  用户资产信息区域-->
    <div class="user-access">
      <div class="access-item  item1">
        <div class="header-image">
          <a-avatar :size="100" icon="user"/>
        </div>
        <div class="account-info">
          <div style="color:#26AAFE;font-size: 16px;">{{ this.$store.state.user.userInfo.phone }}</div>
          <div style="margin-top: 8px">
            <span style="color: #C4C4C4">用户编号:</span>
            <span> {{this.$store.state.user.userInfo.id}}</span>
          </div>
          <div style="display: flex;flex-direction: row;margin-top: 5px">
            <span style="color: #C4C4C4">安全等级:</span>
            <span style="display:block;width: 80px"><a-progress :percent="30" size="small" /></span>
          </div>
          <div style="margin-top: 5px">
            <span style="color: #C4C4C4">推广等级:</span>
            <span> 普通推广员</span>
          </div>
        </div>
        <div style="margin-left: 21px">
          <a-button @click="signOut"  size="large">
            退出
          </a-button>
        </div>
      </div>


      <div class="access-item">
        <div style="font-size: 85px;margin-left: 10px;color: #FDA309"><a-icon type="money-collect" /></div>
        <div style="display:block;margin-left: 10px;text-align: left">
          <div style="font-size: 20px;color: #A2A2A2">账户余额(元):</div>
          <div style="color:#FC7F27;font-size: 19px;margin-top: 10px">￥{{this.$store.state.user.userInfo.balance}}</div>
          <div style="margin-top: 10px">
            <a-button @click="gotoTransactionInfo"  type="primary" ghost>
              查看明细
            </a-button>
          </div>
        </div>

      </div>
      <div class="access-item">
        <div style="font-size: 85px;margin-left: 10px;color: #3454FF">
          <a-icon type="import" />
        </div>
        <div style="display:block;margin-left: 10px;text-align: left">
          <div style="font-size: 20px;color: #A2A2A2">提现处理中的金额:</div>
          <div style="color:#FC7F27;font-size: 19px;margin-top: 10px">￥{{this.$store.state.user.userInfo.frozenAmount}}</div>
          <div style="margin-top: 10px">
            <a-button type="danger" @click="gotoWithdraw" ghost>
              我要提现
            </a-button>
            <a-button style="margin-left: 20px" @click="gotoWithDrawRecord" type="primary" >
              提现记录
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <!--  用户认证信息区域-->
    <div class="user-authenticate">
      <div v-if="this.$store.state.user.userInfo.phone==null" class="auth-card" style="background-color:#F8FBFF;" >
        <div style="margin-top: 30px;color: #D4D5D5;font-size: 80px;"><a-icon type="mobile" /></div>
        <div style="margin-top: 2px;color: #AFB1B2"><span>手机号未绑定</span></div>
         <div style="margin-top: 5px">
           <a-button type="primary" @click="gotoBindPhone" icon="phone" >
             绑定手机号
           </a-button>
         </div>
      </div>
      <div v-else class="auth-card" style="background-color:#F674A8" >
        <div style="margin-top: 30px;color:white;font-size: 80px;"><a-icon type="mobile" /></div>
        <div style="margin-top: 2px;color: white"><span>{{this.$store.state.user.userInfo.phone}}</span></div>
        <div style="margin-top: 5px">
          <a-button icon="phone" @click="gotoBindPhone">更换手机</a-button>
        </div>
      </div>

      <div v-if="this.$store.state.user.userInfo.realNameStatus!=2" class="auth-card" style="background-color:#F8FBFF;" >
        <div style="margin-top: 30px;color: #D4D5D5;font-size: 80px;"><a-icon type="idcard" /></div>
        <div style="margin-top: 2px;color: #AFB1B2"><span>未实名认证</span></div>
        <div style="margin-top: 5px">
          <a-button type="primary" icon="user" @click="gotoRealName" >
            实名认证
          </a-button>
        </div>
      </div>
      <div v-else class="auth-card" style="background-color:#9EDD18" >
        <div style="margin-top: 30px;color:white;font-size: 80px;"><a-icon type="idcard" /></div>
        <div style="margin-top: 2px;color: white"><span>账号已实名认证</span></div>
        <div style="margin-top: 5px">
          <a-button icon="idcard">已实名认证</a-button>
        </div>
      </div>

      <div v-if="this.$store.state.user.userInfo.transactionPassword==null" class="auth-card" style="background-color:#F8FBFF;" >
        <div style="margin-top: 30px;color: #D8575A;font-size: 80px;"><a-icon type="lock" /></div>
        <div style="margin-top: 2px;color: #AFB1B2"><span>交易密码未设置</span></div>
        <div style="margin-top: 5px">
          <a-button type="primary" @click="gotoBindTransactionPassword" icon="lock" >
            设置密码
          </a-button>
        </div>
      </div>
      <div v-else class="auth-card" style="background-color:#F6CA25" >
        <div style="margin-top: 30px;color:white;font-size: 80px;"><a-icon type="lock" /></div>
        <div style="margin-top: 2px;color: white"><span>交易密码设置完成</span></div>
        <div style="margin-top: 5px">
          <a-button icon="lock" @click="gotoBindTransactionPassword" >修改密码</a-button>
        </div>
      </div>

      <div v-if="this.$store.state.user.userInfo.email==null" class="auth-card" style="background-color:#F8FBFF;" >
        <div style="margin-top: 30px;color: #D4D5D5;font-size: 80px;"><a-icon type="mail" /></div>
        <div style="margin-top: 2px;color: #AFB1B2"><span>绑定邮箱可增强账户安全</span></div>
        <div style="margin-top: 5px">
          <a-button type="primary" @click="gotoBindEmail" icon="mail" >
            绑定邮箱
          </a-button>
        </div>
      </div>
      <div v-else class="auth-card" style="background-color:#ADB6F7" >
        <div style="margin-top: 30px;color:white;font-size: 80px;"><a-icon type="mail" /></div>
        <div style="margin-top: 2px ;color: white"><span>itchaihaobo@163.com</span></div>
        <div style="margin-top: 5px">
          <a-button icon="lock" @click="gotoBindEmail">更换邮箱</a-button>
        </div>
      </div>

      <div v-if="this.$store.state.user.userInfo.wechat==null" class="auth-card" style="background-color:#F8FBFF;" >
        <div style="margin-top: 30px;color: #D4D5D5;font-size: 80px;"><a-icon type="wechat" /></div>
        <div style="margin-top: 2px;color: #AFB1B2"><span>绑定微信后可使用微信登录</span></div>
        <div style="margin-top: 5px">
          <a-button type="primary" icon="wechat" >
            绑定微信
          </a-button>
        </div>
      </div>
      <div v-else class="auth-card" style="background-color:#8CD6FD" >
        <div style="margin-top: 30px;color:white;font-size: 80px;"><a-icon type="wechat" /></div>
        <div style="margin-top: 2px ;color: white"><span>已绑定微信</span></div>
        <div style="margin-top: 5px">
          <a-button icon="lock">更改绑定</a-button>
        </div>
      </div>
      <div v-if="this.$store.state.user.userInfo.qq==null" class="auth-card" style="background-color:#F8FBFF;" >
        <div style="margin-top: 30px;color: #D4D5D5;font-size: 80px;"><a-icon type="qq" /></div>
        <div style="margin-top: 2px;color: #AFB1B2"><span>绑定QQ可以QQ登录</span></div>
        <div style="margin-top: 5px">
          <a-button type="primary" icon="qq" >
            绑定QQ
          </a-button>
        </div>
      </div>
      <div v-else class="auth-card" style="background-color:#FD9F4A" >
        <div style="margin-top: 30px;color:white;font-size: 80px;"><a-icon type="qq" /></div>
        <div style="margin-top: 2px ;color: white"><span>该账号已绑定QQ</span></div>
        <div style="margin-top: 5px">
          <a-button icon="qq">更改绑定</a-button>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
export default {
  name: "Index",
  data(){
    return {

    }

  },
  computed:{
  },
  methods:{
    gotoBindEmail(){
      this.$router.push('/center/baseManage?tab=emailSetting')
    },
    gotoBindTransactionPassword(){
      this.$router.push('/center/baseManage?tab=passwordManage&passwordTab=2')
    },
    gotoBindPhone(){
      this.$router.push('/center/baseManage?tab=phoneSetting')
    },
    gotoRealName(){
      this.$router.push('/center/baseManage?tab=relName')
    },
    gotoWithDrawRecord(){
      this.$router.push('/center/withdraw/record')
    },
    gotoWithdraw(){
      this.$router.push('/center/withdraw')
    },
    gotoTransactionInfo(){
      this.$router.push('/center/transactionRecord')
    },
    signOut(){
      this.$store.commit('user/signOut')
    }
  }
}
</script>

<style scoped>
.auth-card:hover{
  transform: translateY(-10px);
  box-shadow: 4px 4px 8px 2px #d2f1ff;
}
.auth-card{
  width: 200px;
  height: 240px;
  position: relative;
  border: 1px solid #e4ecfa;
  top: 30px;
  border-radius: 10px;
  transition:all 0.2s linear;
  margin-left: 40px;
  margin-top: 20px;
  box-shadow: 2px 4px 10px 0 #ededed;;
}


.user-authenticate {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  background-color: white;
  padding-bottom: 50px;

}
.account-info{
  text-align: left;
}
.header-image {
  width: 150px;
}

.item1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.access-item {
  width: 100%;
  height: 100%;
  border-right: 2px dashed #e2e2e2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.access-item:last-child {
  border-right: 0
}

.user-access {
  height: 200px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  background-color: white;

}


</style>