import axios from 'axios'
import {message} from 'ant-design-vue'
import router from "@/router";
// axios 配置
const api = axios.create({
    timeout: 50000 // request timeout
})

api.interceptors.request.use((config) => {

    if (config.method === 'get') {
        // config.data = qs.stringify(config.data);
        config.data = JSON.stringify(config.data);
    }
    let token = localStorage.getItem('token');
    if (token != undefined && token !== '') {
        config.headers['X-TOKEN'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

//返回状态判断
api.interceptors.response.use((res) => {
    let data = res.data;
    if (data.code === "403") {
        message.error('请登录')
        setTimeout(() => {
            router.push('/signin')
        }, 1000)
        return ;
    }
    if (data.code !== '200') {
        message.error(data.message)
        return Promise.reject(data.message);
    }
    return res;
}, (error) => {
    //404等问题可以在这里处理
    message.error("服务器发生异常")
    return Promise.reject(error);
});

export default api
