<template>
  <div class="email-setting-container">
    <div style="height: 100%;width: 100%;">
      <div class="phone-setting">
        <div class="info">
          <div v-if="this.$store.state.user.userInfo.email!=null" class="phone-icon">
            <a-icon type="mail"/>
          </div>
          <div v-else class="phone-icon" style="color: red">
            <a-icon type="mail"/>
          </div>
          <div class="text-info">
            <div v-if="this.$store.state.user.userInfo.email != null"
                 style="color: #7EBA19;height: 50px;line-height: 50px;font-size: 15px;font-weight: bold;margin-top: 20px;text-align: left">
              当前状态：邮箱已绑定({{ this.$store.state.user.userInfo.email }})
            </div>
            <div v-else
                 style="color: red;height: 50px;line-height: 50px;font-size: 15px;font-weight: bold;margin-top: 20px;text-align: left">
              当前状态：邮箱未绑定
            </div>
            <div style="text-align: left;color: #B1B1B0">
              绑定邮箱可用于安全验证、找回密码等重要操作
            </div>
            <div style="margin-top: 20px">
              <a-button @click="openChangeEmailModal" v-if="this.$store.state.user.userInfo.email != null"
                        style="margin-left: -196px" icon="mail" type="primary">
                更换邮箱
              </a-button>
              <a-button @click="openBindEmailModal" v-else style="margin-left: -196px" icon="mail" type="primary">
                绑定邮箱
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="phone-description">
        <div style="height: 30px;line-height: 30px;font-size: 16px;text-align: left;color:#A2A2A2">
          <a-icon type="bell"/>
          温馨提示:
        </div>
        <div style="height: 20px;line-height: 20px;font-size: 12px;text-align: left;color:#A2A2A2">
          1、您的邮箱绑定之后，即可享受邮箱登录、找回密码等服务，让您的网上购物体验更安全更便捷；
        </div>
        <div style="height: 40px;line-height: 20px;font-size: 12px;text-align: left;color:#A2A2A2">
          2、若您的邮箱可正常使用但无法接收到验证码，请检查您的垃圾邮件或广告邮件，将发件人设置为白名单，如果5分钟内仍未
          收到邮箱验证码，您可以重新发送；
        </div>
        <div style="height: 20px;line-height: 20px;font-size: 12px;text-align: left;color:#A2A2A2">
          3、如果您的邮箱已经无法正常使用，请提供用户名，手机号，身份证号，点击联系平台 在线客服
        </div>

        <div>
        </div>
        <a-modal
            v-model="showChangeEmailModal"
            title="更换邮箱邮箱"
            centered
            @ok="changeEmail"
        >
          <div>
            <a-form-model ref="changeEmailForm" :model="changeEmailForm" v-bind="layout">
              <a-form-model-item label="邮箱地址">
                <a-input style="width: 150px" v-model="changeEmailForm.email"/>
              </a-form-model-item>
              <a-form-model-item label="验证码" prop="newPhoneCode">
                <a-row>
                  <a-col :span="12">
                    <a-input v-model="changeEmailForm.code" style="width: 150px"/>
                  </a-col>
                  <a-col :span="12">
                    <CodeButton @click="sendEmailCode"></CodeButton>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-modal>
        <a-modal
            v-model="showBindEmailModal"
            title="绑定邮箱邮箱"
            centered
            @ok="bindEmail"
        >
          <div>
            <a-form-model ref="bindEmailForm" :model="bindEmailForm" v-bind="layout">
              <a-form-model-item label="新邮箱地址">
                <a-input style="width: 150px" v-model="bindEmailForm.email"/>
              </a-form-model-item>
              <a-form-model-item label="验证码" prop="newPhoneCode">
                <a-row>
                  <a-col :span="12">
                    <a-input v-model="bindEmailForm.code" style="width: 150px"/>
                  </a-col>
                  <a-col :span="12">
                    <CodeButton @click="sendBindEmailCode"></CodeButton>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-form-model>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import CodeButton from "@/components/CodeButton";
import {getEmailCode, changeUserEmail,bindUserEmail} from "@/api/customer"

export default {
  name: "EmailSettingTab",
  components: {
    CodeButton
  },
  data() {
    return {
      showChangeEmailModal: false,
      showBindEmailModal:false,
      changeEmailForm: {
        email: "",
        code: "",
      },
      bindEmailForm: {
        email: "",
        code: "",
      },
      layout: {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
      },
    }
  },
  methods: {
    bindEmail(){
      //验证是否新邮箱和验证码是否为空
      if (this.bindEmailForm.email === "") {
        this.$message.error("新邮箱地址为空")
        return
      }
      if (this.bindEmailForm.code == "") {
        this.$message.error("验证码为空")
        return;
      }
      bindUserEmail(this.bindEmailForm)
      .then(()=>{
        this.$message.success("绑定成功")
        this.$store.commit("user/getUserInfo")
        this.closeBindEmailModal()
      })


    },
    changeEmail() {
      //验证是否新邮箱和验证码是否为空
      if (this.changeEmailForm.email === "") {
        this.$message.error("新邮箱地址为空")
        return
      }
      if (this.changeEmailForm.code == "") {
        this.$message.error("验证码为空")
        return;
      }
      changeUserEmail(this.changeEmailForm)
          .then(() => {
            this.$message.success("修改成功")
            this.closeChangeEmailModal()
            this.$store.commit("user/getUserInfo")
          })


    },
    closeChangeEmailModal() {
      this.showChangeEmailModal = false;
    },
    closeBindEmailModal() {
      this.showBindEmailModal = false;
    },
    openChangeEmailModal() {
      this.showChangeEmailModal = true;
    },
    openBindEmailModal() {
      this.showBindEmailModal = true;
    },
    sendEmailCode(callback) {
      getEmailCode(this.$store.state.user.userInfo.email)
      callback(true)

    },
    sendBindEmailCode(callback) {
      if (this.bindEmailForm.email === ""){
        this.$message.error("请输入邮箱地址")
        callback(false)
        return
      }
      getEmailCode(this.bindEmailForm.email)
      callback(true)

    }
  }
}
</script>

<style scoped>

.text-info {
  width: 100%;
  height: 100%;
}

.phone-icon {
  height: 100%;
  width: 100px;
  line-height: 200px;
  font-size: 70px;
  color: #7EBA19;
}

.info {
  width: 400px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.phone-setting {
  width: 600px;
  height: 300px;
  margin: 0 auto;
  background-color: #F8FCF1;
  padding-top: 50px;
}

.phone-description {
  width: 600px;
  height: 300px;
  margin: 0 auto;

}

.email-setting-container {
  padding: 60px 20px 20px;
  height: 600px;
}

</style>