<template>
  <div>
    <div id="card-container" class="card-container" :style="'display: '+display">
      <div v-for="cardType in this.$store.state.card.cardTypes" :key="cardType.id" class="card">
        <a href="#" @click="selctCardType(cardType.id)">
          <a-card hoverable>
            <img
                slot="cover"
                alt="example"
                :src="cardType.image"
            />
            <a-card-meta :title="cardType.name"></a-card-meta>
          </a-card>
        </a>
      </div>


    </div>
  </div>


</template>

<script>
export default {
  name: "CardTypePreView",
  props: {
    display: {
      type: String,
      default: ""
    }
  },
  mounted() {
    document.onmousemove = (e) => {
      let clientX = e.clientX;
      let clientY = e.clientY;
      if (clientX >= 182 && clientY >= 77) {
        let cardContainer = document.getElementById("card-container");
        if (cardContainer != null) {
          let offsetWidth = cardContainer.offsetWidth;
          let offsetHeight = cardContainer.offsetHeight;
          if (clientX < 182 || clientX > 182 + offsetWidth || clientY > 77 + offsetHeight) {
            this.$emit("onmouseout")
          }
        }
      }

    }
  }
  ,
  methods: {
    getTop(e) {
      let offset = e.offsetTop;
      if (e.offsetParent != null) offset += this.getTop(e.offsetParent);
      return offset;
    }
    ,
    getLeft(e) {
      let offset = e.offsetLeft;
      if (e.offsetParent != null) offset += this.getLeft(e.offsetParent);
      return offset;
    },
    selctCardType(cardTypeId){
      this.$router.push({
         path:"/sellCard",
         query:{
           cardTypeId:cardTypeId
         }
       })
       this.display="none"
      
    }
  }
}
</script>

<style scoped>
.card-container {
  width: 645px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0 20px;
  position: absolute;
  z-index: 9999;
  background-color: white;
  border-radius: 10px;
  left: 182px;
  top: 77px;
  border: 1px solid #EFEFEF;


}

.card {
  width: 200px;
  height: 180px;
  padding: 24px 20px 0;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 10px;

}

.ant-card-bordered {
  border: 1px solid #e8e8e8;
  height: 156px;
}

</style>