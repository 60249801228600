import api from './index'
import interfaces from "../constant/interfaces"

/**
 * 所有有效卡类
 */
export function getAllCardType() {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.GET_CARD_TYPE_ACTIVE)}`)
}

/**
 * 所有有效卡种
 * @param id 卡类id
 */
export function getAllCard(id) {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.GET_CARD_ACTIVE)}/${id}`)
}

/**
 * 所有有效面值
 * @param cardId cardId
 */
export function getAllCardPrice(cardId){
    return api.get(`${interfaces.GET_INTERFACE(interfaces.GET_CARD_PRICE_ACTIVE)}/${cardId}`)

}