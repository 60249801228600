<template>
  <div style="background-color:white;height: 800px;overflow: scroll;padding: 0 20px 30px;">
    <div style="height: 100px;line-height: 100px;font-size: 20px;text-align: left">全部订单-订单详情</div>
    <div class="info-area">
      <div class="order-info">
        <span style="font-size: 24px;font-weight: 500;color: #000">单号：{{ this.orderInfo.orderId }} </span>
        <span style="font-size: 22px;font-weight: 500;color: rgb(153, 153, 153)">| 提交时间：{{
            this.orderInfo.createTime
          }}</span>
        <a style="position: absolute;right: 20px" @click="goBack">返回全部订单</a>
      </div>
    </div>

    <div style="width: 100%;margin-top: 40px;height: 450px;background-color:#F4FEFF;border-radius: 3px">
      <a-row>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="卡种" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderInfo.cardName }}
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="卡号" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderInfo.account }}
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="卡类" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderInfo.cardTypeName }}
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="卡密" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderInfo.password }}
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="提交面值" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderInfo.price }}
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="实际面值" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderInfo.finalPrice }}
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="供货折扣" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderInfo.userRate }}折
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="实际可得" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  ￥{{ orderInfo.finalPrice * orderInfo.userRate /100 }}

                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="提交时间" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderInfo.createTime }}
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="处理时间" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderInfo.finishTime }}
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="耗时" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ this.useTime }}s
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item style="height: 40px;color: #757676" label="状态" :labelCol="{span:5}">
            <a-row>
              <a-col :offset="5">
                <div style="height:40px;line-height: 60px">
                  {{ orderStatus[orderInfo.status] }}
                </div>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-row>
          <a-col :span="12">
            <a-form-model-item style="height: 40px;color: #757676" label="备注" :labelCol="{span:5}">
              <a-row>
                <a-col :offset="5">
                  <div style="height:40px;line-height: 60px">
                    {{ orderInfo.remarks }}

                  </div>
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-row>

    </div>


  </div>
</template>

<script>
import emuns from '@/constant/emuns'
import orderApi from '@/api/order'
import moment from 'moment'

export default {
  name: "Index",
  data() {
    return {
      orderInfo: {},
      orderId: "",
      orderStatus: emuns.orderStatus,
      type: emuns.orderType,
    }
  },
  created() {
    this.orderId = this.$route.query.orderId
    this.getOrderInfo()
  },
  computed: {
    useTime() {
      console.log(moment(this.orderInfo.createTime))
      return moment(this.orderInfo.finishTime).diff(this.orderInfo.createTime, 'second')
    }
  },
  mounted() {
  },
  methods: {
    getOrderInfo() {
      orderApi.getOrderInfo(this.orderId)
          .then(res => {
            this.orderInfo = res.data.data
          })
    },
    search() {
      this.pagination.current = 1
      this.loadData()

    },
    goBack() {
      this.$router.go(-1)
    },

  }
}
</script>

<style scoped>

.order-info {
  height: 88px;
  line-height: 88px;
  position: relative;
  text-align: left;
  padding-left: 20px;
}

.info-area {

  height: 88px;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
}

</style>