<template>
  <div id="sign-up">
    <div class="header">
      <div class="header-content">
        <router-link to="/"><img src="../assets/image/logo.png" alt=""></router-link>
        <div class="header-to-sigin-in">
          已有账号？请
          <router-link to="/signIn">登录</router-link>
        </div>
      </div>

      <div class="sign-in-form">
        <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
          <a-form-model-item has-feedback label="账号" prop="account">
            <a-input v-model="ruleForm.account" placeholder="手机号" autocomplete="off"/>
          </a-form-model-item>
          <a-form-model-item has-feedback label="密码" prop="password">
            <a-input v-model="ruleForm.password" placeholder="注册密码由6-20位字母、数字或者符号两种或以上组成" type="password"
                     autocomplete="off"/>
          </a-form-model-item>
          <a-form-model-item has-feedback label="再次输入" prop="confirmPassword">
            <a-input v-model="ruleForm.confirmPassword" placeholder="再次输入" type="password"/>
          </a-form-model-item>
          <a-form-model-item label="推广员编号">
            <a-input v-model="ruleForm.innerUserId" placeholder="推广员编号(选填)"/>
          </a-form-model-item>
          <a-form-model-item label="注册类型">
            <a-select v-model="ruleForm.userType" placeholder="企业用户需要企业信息认证" >
            <a-select-option value="0" aria-selected="true">个人用户</a-select-option>
            <a-select-option value="1">企业用户</a-select-option>
          </a-select>
          </a-form-model-item>
          <a-form-model-item label="验证码" prop="code">
            <a-row>
              <a-col span="12">
                <a-input v-model="ruleForm.code  " placeholder="验证码"/>
              </a-col>
              <a-col span="12">
                <CodeButton @click="sendCode"></CodeButton>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 2 }">
            <a-button :loading="signUpButtonIsLoading" type="primary" @click="submitForm('ruleForm')">
              注册
            </a-button>
            <a-button style="margin-left: 10px" @click="resetForm('ruleForm')">
              重置
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>


    </div>


  </div>
</template>

<script>
import {isEmailAvailable, isPhoneAvailable, isPasswordAvailable} from '../utils/reg'
import {getSignUpCode, getEmailCode, signUp} from '../api/customer'
import CodeButton from "@/components/CodeButton";

export default {
  name: "SignUp",
  components: {
    CodeButton
  },
  data() {
    let validatePasswordConfirm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      }
      if (value !== this.ruleForm.password) {
        callback(new Error('请输入正确密码'))
      }
      callback()

    };
    let validateAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        if (!isPhoneAvailable(value)) {
          callback(new Error('请输入正确手机号'));
        }
        callback();
      }
    };
    let validatePassword = (rule, value, callback) => {
      if (!isPasswordAvailable(value)) {
        callback(new Error('注册密码由6-20位字母、数字或者符号两种或以上组成'));
      }
      callback();
    };
    return {
      signUpButtonIsLoading:false,
      ruleForm: {
        account: "",
        password: '',
        confirmPassword: '',
        code: '',
        innerUserId: null,
        userType: '0'
      },
      rules: {
        account: [{required: true, validator: validateAccount, trigger: 'blur'}],
        password: [{required: true, validator: validatePassword, trigger: 'blur'}],
        confirmPassword: [{required: true, validator: validatePasswordConfirm, trigger: 'blur'}],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'}],
      },
      layout: {
        labelCol: {span: 4},
        wrapperCol: {span: 14},
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.signUpButtonIsLoading = true;
          signUp({
            account: this.ruleForm.account,
            password: this.ruleForm.password,
            code: this.ruleForm.code,
            userType: this.ruleForm.userType
          }).then(() => {
            this.$message.success("注册成功")
            setTimeout(() => {
              this.$router.push('/')
            }, 1000)
            this.signUpButtonIsLoading = false
          }).catch(()=>{
            this.signUpButtonIsLoading = false;
          })
        } else {
          this.$message.error("请填写正确信息！")
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sendCode(callback) {
      let result = true
      //校验是否是手机号
      let account = this.ruleForm.account;
      if (!isPhoneAvailable(account)) {
        this.$message.error("请输入正确格式手机号")
        result = false
        return
      }
      if (isPhoneAvailable(account)) {
        getSignUpCode(account)
            .then()
            .catch(() => {
              result = false
            })
      }
      if (isEmailAvailable(account)) {
        getEmailCode(account)
            .then()
            .catch(() => {
              result = false
            })
      }


      callback(result)

    }
  },
}
</script>

<style scoped>
.sign-in-form {
  width: 500px;
  margin: 100px auto;
}

.header {
  height: 78px;
}

.header-content {
  flex-direction: row;
  width: 1320px;
  height: 100%;
  margin: 0 auto;
}

.header-content img {
  display: block;
  margin-top: 25px;
  margin-left: 10px;
  width: 151px;
  height: 40px;
  float: left;
}

.header-to-sigin-in {
  float: right;
  color: #535353;
  width: 300px;
  height: 100%;
  margin-right: 10px;
  line-height: 78px;

}

.header-to-sigin-in a {
  color: #26AAFF;
}

.header-to-sigin-in a:hover {
  color: #26AAFF;
}

#sign-up {
  height: 100%;
  width: 100%;
  background: url("../assets/image/sign-up-bg.jpg");
  background-size: cover;
  margin: 0;
  padding: 0;
  position: absolute;

}


</style>