import Vue from 'vue'
import Vuex from 'vuex'
import user from './module/user'
import card from './module/card'
Vue.use(Vuex)


export default new Vuex.Store({
   modules: {
      user,
      card
   }
})
