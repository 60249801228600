import api from './index'
import interfaces from "../constant/interfaces"

/**
 * 提现
 * @param data data
 */
export function submitDraw(data){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.DRAW)}`,data)
}

/**
 * 提现记录
 * @param data data
 */
export function drawList(data){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.DRAW_List)}`,data)
}


/**
 * 查询平台流水
 * @param data
 */
export function searchPlatformFlow(data){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.QUERY_PLATFORM_FLOW)}`,data)
}
/**
 * 查询所有订单
 */
export function searchAllOrder(data){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.QUERY_USER_ORDER)}`,data)
}


export function userOrderStat(data){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.QUERY_USER_STAT)}`,data)
}

/**
 * 查询所有批量订单
 */
export function searchAllBatchOrder(data){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.QUERY_USER_BATCH_ORDER)}`,data)
}

/**
 * 批量提交订单
 */
export function batchSubmit(data) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.ORDER_BATCH_SUBMIT)}`,data)
}

/**
 * 批量订单详情
 * @param batchOrderId 批量订单号
 */
export function getBatchOrderInfo(data){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.GET_BATCH_ORDER_INFO)}`, data)
}

/**
 * 订单详情
 * @param orderId 订单号
 */
export function getOrderInfo(orderId){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.GET_ORDER_INFO)}`, {orderId})
}

export function getLastOrderByPriceId(cardPriceId){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.GET_LAST_ORDER_BY_PRICE_ID)}`, {cardPriceId})
}

export function changeRate(order){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.ORDER_CHANGE_RATE)}`,order)
}

export function failSubmit(order){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.ORDER_FAIL_SUBMIT)}`,order)
}

export function apiCallBack(order){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.ORDER_API_CALLBACK)}`,order)
}

export default {
    batchSubmit,
    searchAllOrder,
    searchAllBatchOrder,
    getBatchOrderInfo,
    getOrderInfo,
    submitDraw,
    drawList,
    getLastOrderByPriceId,
    changeRate,
    failSubmit,
    apiCallBack,
    userOrderStat 
}

