const SERVICE_PREFIX = "/pxapi"
export default {
    SIGN_UP_CODE : "/sms/registerCode",//注册验证码
    SIGN_IN_CODE : "/sms/signInCode",//登录验证码
    CHANGE_PHONE_CODE: "/sms/changePhoneCode",//修改手机号验证码
    CHANGE_PHONE_NEW_CODE: "/sms/newPhoneCode",//修改手机新手机号验证码

    MAIL_CODE : "/mail/code",//注册邮箱验证码


    SIGN_OUT: "/customer/signOut",//注销
    SIGN_UP : "/customer/signup",//注册
    SIGN_IN_ACCOUNT : "/customer/account/signIn",//账号登录
    SIGN_IN_PHONE : "/customer/phone/signIn",//手机登录
    UER_INFO: "/customer/info",//当前登录用户信息
    CHANGE_NICKNAME: "/customer/nickname",//修改昵称
    CHANGE_QQ: "/customer/qq",//修改QQ
    CHANGE_PHONE: "/customer/changePhone",//修改手机号
    EXIST_PHONE: "/customer/existPhone",//是否存在手机号
    BIND_PHONE: "/customer/bindPhone",//绑定手机
    CHANGE_PASSWORD: "/customer/changePassword",//修改密码
    CHANGE_TRANSACTION_PASSWORD: "/customer/transactionPassword",//修改交易密码
    CHANGE_EMAIL: "/customer/email",//修改交易密码
    REAL_NAME: "/customer/realName",//用户实名认证
    LEGAL_PERSON_REAL_NAME: "/customer/legalperson/realName",//修改交易密码
    GET_SIGN_FILE: "/customer/esignFileDownloadUrl",//修改交易密码
    CHANGE_COMPANY_INFO: "/customer/changeCompany",//保存企业认证信息
    QUERY_COMPANY_INFO: "/customer/queryCompany",//保存企业认证信息

    BANK_LIST: "/bank/list",//银行列表
    GET_BANK_CODE_BY_CARD_NUMBER: "/bank/getCodeByCardNumber",//通过银行卡号获取银行编码


    ADD_CARD: "/userBankCard/add",//添加银行卡
    USER_ALL_CARDS: "/userBankCard/userCards",//用户所有银行卡
    DELETE_USER_CARD: "/userBankCard",//删除用户银行卡


    ORDER_BATCH_SUBMIT:"/order/batchSubmit",//批量提交订单
    ORDER_FAIL_SUBMIT:"/order/failSubmit",//批量提交订单
    ORDER_API_CALLBACK:"/order/api/callback",//批量提交订单
    QUERY_USER_ORDER:"/order/queryUserOrder",//查询用户订单
    QUERY_USER_STAT:"/order/userStat",//查询用户订单
    QUERY_USER_BATCH_ORDER:"/order/queryUserBatchOrder",//查询用户批量订单
    GET_BATCH_ORDER_INFO:"/order/batchOrderInfo",
    GET_ORDER_INFO:"/order/orderInfo",//订单详情
    GET_LAST_ORDER_BY_PRICE_ID:"/order/getLastOrderByPriceId",//获取最后卡种类型的订单
    ORDER_CHANGE_RATE:"/order/changeRate",//修改订单折扣
    QUERY_PLATFORM_FLOW:"/flow/list",//平台流水
    DRAW:"/order/draw",//平台流水
    DRAW_List:"/order/drawList",//平台流水


    ARTICLE_CATEGORY_LIST:"/article/category/list",//文章类目列表
    ARTICLE_LIST:"/article/list",//文章列表
    ARTICLE_INFO:"/article",//文章详情
    ARTICLE_POST:"/article/post",//文章公告

    GET_CARD_TYPE_ACTIVE : "/cardType/allActive",//所有有效卡类
    GET_CARD_ACTIVE : "/card/allActive",//所有有效卡种
    GET_CARD_PRICE_ACTIVE: "/cardPrice/allActive",//所有有效面值

    LINKS:"/link/list",

    BANNER_LIST:"/banner/list",
    CONFIG_GET:"/config",

    GET_INTERFACE : (url) => SERVICE_PREFIX + url,


}