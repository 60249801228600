<template>
  <div id="sign-up">
    <div class="header">
      <div class="header-content">
        <router-link to="/"><img src="../assets/image/logo.png" alt=""></router-link>
        <div class="header-to-sigin-in">
          <router-link to="/">返回首页</router-link>
        </div>
      </div>
      <div class="sign-in-form">
        <a-tabs @change="changeTab">
          <a-tab-pane key="accountForm" tab="账号密码登录">
            <a-form-model ref="accountForm" :model="accountForm" :rules="rules" v-bind="layout">
              <a-form-model-item has-feedback label="账号" prop="account">
                <a-input v-model="accountForm.account" placeholder="手机号" autocomplete="off"/>
              </a-form-model-item>
              <a-form-model-item has-feedback label="密码" prop="password">
                <a-input v-model="accountForm.password" placeholder="注册密码由6-20位字母、数字或者符号两种或以上组成" type="password"
                         autocomplete="off"/>
              </a-form-model-item>
            </a-form-model>
          </a-tab-pane>
          <a-tab-pane key="codeForm" tab="手机验证码登录">
            <a-form-model ref="codeForm" :model="codeForm" :rules="rules" v-bind="layout">
              <a-form-model-item has-feedback label="账号" prop="phone">
                <a-input v-model="codeForm.phone" placeholder="手机号" autocomplete="off"/>
              </a-form-model-item>
              <a-form-model-item label="验证码" prop="code">
                <a-row>
                  <a-col span="12">
                    <a-input v-model="codeForm.code  " placeholder="验证码"/>
                  </a-col>
                  <a-col span="12">
                    <CodeButton @click="sendCode"></CodeButton>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-form-model>
          </a-tab-pane>
        </a-tabs>
        <div class="sign-in-btn">
          <a-button :loading="isLogining" type="primary" size="large" @click="submitForm(currentTabKey)">
            登录
          </a-button>
        </div>

      </div>


    </div>


  </div>
</template>

<script>
import {isEmailAvailable, isPhoneAvailable, isPasswordAvailable} from '../utils/reg'
import {accountSignIn, phoneSignIn, signInCode} from '../api/customer'
import CodeButton from "@/components/CodeButton";

export default {
  name: "SignUp",
  components: {
    CodeButton
  },
  data() {
    let validateAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        if (!isPhoneAvailable(value) && !isEmailAvailable(value)) {
          callback(new Error('请输入正确手机号'));
        }
        callback();
      }
    };
    let validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        if (!isPhoneAvailable(value)) {
          callback(new Error('请输入正确手机号'));
        }
        callback();
      }
    };
    let validatePassword = (rule, value, callback) => {
      if (!isPasswordAvailable(value)) {
        callback(new Error('密码由6-20位字母、数字或者符号两种或以上组成'));
      }
      callback();
    };
    return {
      isLogining: false,
      currentTabKey: 'accountForm',
      signUpButtonIsLoading: false,
      accountForm: {
        account: '',
        password: '',
      },
      codeForm: {
        phone: '',
        code: '',
      },
      rules: {
        account: [{required: true, validator: validateAccount, trigger: 'blur'}],
        password: [{required: true, validator: validatePassword, trigger: 'blur'}],
        phone: [{required: true, validator: validatePhone, trigger: 'blur'}],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'}],
      },
      layout: {
        labelCol: {span: 4},
        wrapperCol: {span: 14},
      },
    };
  },
  methods: {
    changeTab(activeKey) {
      this.currentTabKey = activeKey;
    },
    loginSuccess() {
      this.$message.success("登录成功")
      setTimeout(() => {
        this.$router.push('/')
      }, 1000)
    },
    submitForm(formName) {
      this.isLogining = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.currentTabKey === 'accountForm') {
            //账号登录
            accountSignIn(this.accountForm)
                .then((res) => {
                  let token = res.data.data.token
                  localStorage.setItem("token", token)
                  this.loginSuccess()
                })
                .catch(() => {
                  this.isLogining = false
                })
          } else {
            //验证码登录
            phoneSignIn(this.codeForm)
                .then((res) => {
                  let token = res.data.data.token
                  localStorage.setItem("token", token)
                  this.loginSuccess()
                })
                .catch(() => {
                  this.isLogining = false
                })
          }
        } else {
          this.$message.error("请填写正确信息！")
          this.isLogining = false
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    sendCode(callback) {
      let result = true
      //校验是否是手机号
      let account = this.codeForm.phone;
      if (!isPhoneAvailable(account)) {
        this.$message.error("请输入正确格式手机号")
        result = false
        return
      }
      signInCode(account)
            .then()
            .catch(() => {
              result = false
            })
      callback(result)

    }
  },
}
</script>

<style scoped>
.sign-in-btn {
  margin-left: -50px;
  margin-top: 6px;
}

.sign-in-btn .ant-btn-primary {
  width: 100px;
}

.sign-in-form {
  width: 500px;
  margin: 100px auto;
}

.header {
  height: 78px;
}

.header-content {
  flex-direction: row;
  width: 1320px;
  height: 100%;
  margin: 0 auto;
}

.header-content img {
  display: block;
  margin-top: 25px;
  margin-left: 10px;
  width: 151px;
  height: 40px;
  float: left;
}

.header-to-sigin-in {
  float: right;
  color: #535353;
  width: 300px;
  height: 100%;
  margin-right: 10px;
  line-height: 78px;

}

.header-to-sigin-in a {
  color: #26AAFF;
}

.header-to-sigin-in a:hover {
  color: #26AAFF;
}

#sign-up {
  height: 100%;
  width: 100%;
  background: url("../assets/image/sign-up-bg.jpg");
  /* background: url("../assets/image/login.jpg"); */
  background-size: cover;
  margin: 0;
  padding: 0;
  position: absolute;

}


</style>