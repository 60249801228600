<template>
  <div class="person-container">
    <div class="bar">
      <a-menu
          :default-selected-keys="['1']"
          :default-open-keys="['sub1']"
          mode="inline"
          theme="dark"
          :inline-collapsed="collapsed"
          @click="menuClick"
      >
        <a-menu-item key="accountinfo">
          <a-icon type="pie-chart"/>
          <span>账户总览</span>
        </a-menu-item>
        <a-menu-item key="transactionRecord">
          <a-icon type="desktop"/>
          <span>账户流水</span>
        </a-menu-item>
        <a-menu-item key="withdraw">
          <a-icon type="inbox"/>
          <span>我要提现</span>
        </a-menu-item>
        <a-menu-item key="withdraw/record">
          <a-icon type="inbox"/>
          <span>提现记录</span>
        </a-menu-item>
        <a-sub-menu key="sub1">
          <span slot="title"><a-icon type="mail"/><span>卖卡记录</span></span>
          <a-menu-item key="order/allOrder">
            全部订单
          </a-menu-item>
          <a-menu-item key="order/batchOrder">
            批量订单
          </a-menu-item>
         <a-menu-item key="order/orderStat">
            订单统计
         </a-menu-item>
        </a-sub-menu>
<!--        <a-menu-item key="9">-->
<!--          <a-icon type="inbox"/>-->
<!--          <span>邀请管理</span>-->
<!--        </a-menu-item>-->
        <a-menu-item key="baseManage">
          <a-icon type="inbox"/>
          <span>资料管理</span>
        </a-menu-item>
      </a-menu>
    </div>
    <div class="bar-content">
      <div class="content-info">
        <router-view>


        </router-view>

      </div>


    </div>


  </div>

</template>

<script>
export default {
  name: "index",
  data() {
    return {
      collapsed: false,
    };
  },
  created() {
    this.$store.commit("user/getUserInfo")
  },
  methods: {
    menuClick({key}) {
      if (this.$route.path !== `/center/${key}`) {
        this.$router.push(`/center/${key}`)
      }

    }
  },
}
</script>

<style scoped>
.content-info {
  margin: 10px;
}

.bar {
  width: 186px;
  height: 100%;
}

.bar-content {
  height: 100%;
  width: 100%;
  left: 206px;
  background-color: #F2F2F2;
  padding: 10px;
}

.ant-menu-inline {
  width: 100%;
  height: 100%;
}

html body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.person-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  padding-top: 40px;

}
</style>