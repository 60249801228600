<template>
  <div class="manage-container">
    <a-tabs :activeKey="currentKey" @change="changeTab">
      <a-tab-pane key="base" tab="基本资料">
        <BaseInfoTab @toBindPhone="toBindPhoneTab" @toRealName="toRealName" @toBindEmailTab="toBindEmailTab"></BaseInfoTab>
      </a-tab-pane>
      <a-tab-pane key="phoneSetting" tab="手机设置" force-render>
        <PhoneSettingTab></PhoneSettingTab>
      </a-tab-pane>
      <a-tab-pane key="relName" tab="实名认证">
        <RealNameTab></RealNameTab>
      </a-tab-pane>
      <a-tab-pane key="company" tab="企业认证" v-if="this.$store.state.user.userInfo.userType == '1' ">
        <CompanyTab></CompanyTab>
      </a-tab-pane>
      <a-tab-pane key="bankAccount" tab="银行账号">
        <BankSettingTab ></BankSettingTab>
      </a-tab-pane>
      <a-tab-pane key="alipay" tab="支付宝账号">
        <AliPaySettingTab ></AliPaySettingTab>
      </a-tab-pane>
      <a-tab-pane key="6" tab="微信账号">
        Content of Tab Pane 3
      </a-tab-pane>
      <a-tab-pane key="passwordManage" tab="密码管理">
        <PasswordManageTab></PasswordManageTab>
      </a-tab-pane>
      <a-tab-pane key="emailSetting" tab="邮箱设置">
        <EmailSettingTab></EmailSettingTab>
      </a-tab-pane>
      <!--
      <a-tab-pane key="9" tab="QQ绑定">
        Content of Tab Pane 3
      </a-tab-pane>
      <a-tab-pane key="10" tab="微信绑定">
        Content of Tab Pane 3
      </a-tab-pane> -->
    </a-tabs>
  </div>
</template>

<script>
import AliPaySettingTab from "@/components/AliPaySettingTab";
import BaseInfoTab from "@/components/BaseInfoTab";
import PhoneSettingTab from "@/components/PhoneSettingTab";
import RealNameTab from "@/components/RealNameTab";
import BankSettingTab from "@/components/BankSettingTab";
import PasswordManageTab from "@/components/PasswordManageTab";
import EmailSettingTab from "@/components/EmailSettingTab";
import CompanyTab from "@/components/CompanyTab";

export default {
  name: "Index",
  components: {
    AliPaySettingTab,
    BaseInfoTab,
    PhoneSettingTab,
    RealNameTab,
    BankSettingTab,
    PasswordManageTab,
    EmailSettingTab,
    CompanyTab
  },
  created() {
    let tab = this.$route.query.tab;
    console.log(tab)
    if (tab !== undefined) {
      this.currentKey = tab;
    }

  },
  data() {
    return {
      currentKey: "base"
    }
  },
  methods:{
    toRealName(){
      this.$router.replace("/center/baseManage?tab=relName")
      this.currentKey = 'relName'
    },
    toBindPhoneTab(){
      this.$router.replace("/center/baseManage?tab=phoneSetting")
      this.currentKey = 'phoneSetting'
    },
    toBindEmailTab(){
      this.$router.replace("/center/baseManage?tab=emailSetting")
      this.currentKey = 'emailSetting'
    },
    changeTab(key){
      this.$router.replace(`/center/baseManage?tab=${key}`)
      this.currentKey = key
    },
  }
}
</script>

<style scoped>
.manage-container {
  background-color: white;
  width: 95%;
}

</style>