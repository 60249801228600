<template>
  <div class="phone-setting-container">
    <div style="height: 100%;width: 100%;">
      <div class="phone-setting">
        <div class="info">
          <div v-if="this.$store.state.user.userInfo.phone!=null"  class="phone-icon">
            <a-icon type="mobile"/>
          </div>
          <div v-else  class="phone-icon" style="color: red">
            <a-icon type="mobile"/>
          </div>
          <div class="text-info">
            <div v-if="this.$store.state.user.userInfo.phone!=null"
                style="color: #7EBA19;height: 50px;line-height: 50px;font-size: 15px;font-weight: bold;margin-top: 20px;text-align: left">
              当前状态：已绑定手机（{{ this.$store.state.user.userInfo.phone }}）
            </div>
            <div v-else
                 style="color: red;height: 50px;line-height: 50px;font-size: 15px;font-weight: bold;margin-top: 20px;text-align: left">
              当前状态：未绑定手机
            </div>

            <div style="text-align: left;color: #B1B1B0">
              绑定手机可以用于安全身份验证、找回密码等重要操作如有问题，请联系客服咨询
            </div>
            <div style="margin-top: 20px">
              <a-button v-if="this.$store.state.user.userInfo.phone!=null" @click="showPhoneModal" style="margin-left: -196px" icon="phone" type="primary">
                更换手机
              </a-button>
              <a-button v-else @click="showPhoneModal" style="margin-left: -196px" icon="phone" type="primary">
                绑定手机
              </a-button>
            </div>

          </div>

        </div>

      </div>
      <div class="phone-description">
        <div style="height: 30px;line-height: 30px;font-size: 16px;text-align: left;color:#A2A2A2">
          <a-icon type="bell"/>
          温馨提示:
        </div>
        <div style="height: 20px;line-height: 20px;font-size: 12px;text-align: left;color:#A2A2A2">
          1、您的手机绑定之后，即可享受手机登录、找回密码等服务，让您的网上购物体验更安全更便捷
        </div>
        <div style="height: 20px;line-height: 20px;font-size: 12px;text-align: left;color:#A2A2A2">
          2、若您的手机可正常使用但无法接收到验证码短信，可能是由于通信网络异常造成的，请您稍后重新尝试操作。
        </div>
        <div style="height: 20px;line-height: 20px;font-size: 12px;text-align: left;color:#A2A2A2">
          3、如果您的手机已经无法正常使用，请提供用户名，手机号，身份证号，点击联系平台 <a>在线客服</a></div>
      </div>
      <!--    更换手机对话框-->
      <a-modal
          v-model="showBindPhoneModal"
          title="绑定手机"
          :confirmLoading="bindPhoneCofirmLoading"
          centered
          @ok="submitChangeForm"
      >
        <div>
          <a-form-model ref="changePhoneForm" :model="changePhoneForm" :rules="changePhoneFormRules" v-bind="layout">
            <a-form-model-item label="您当前绑定的手机号">
              <span style="width: 100px">{{ this.$store.state.user.userInfo.phone }}</span>
            </a-form-model-item>
            <a-form-model-item label="短信验证码" prop="originPhoneCode">
              <a-row>
                <a-col :span="12">
                  <a-input style="width: 150px" v-model="changePhoneForm.originPhoneCode"/>
                </a-col>
                <a-col :span="12">
                  <CodeButton @click="sendOriginPhoneCode"></CodeButton>
                </a-col>
              </a-row>
            </a-form-model-item>
            <a-form-model-item label="新手机号" prop="newPhone">
              <a-input style="width: 150px" v-model="changePhoneForm.newPhone"/>
            </a-form-model-item>
            <a-form-model-item label="新手机短信验证码" prop="newPhoneCode">
              <a-row>
                <a-col :span="12">
                  <a-input v-model="changePhoneForm.newPhoneCode" style="width: 150px"/>
                </a-col>
                <a-col :span="12">
                  <CodeButton @click="sendNewPhoneCode"></CodeButton>
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>

    </div>

<!--    绑定手机-->
    <div>
      <a-modal
          v-model="showAddPhoneModal"
          title="绑定手机"
          :confirmLoading="bindPhoneCofirmLoading"
          centered
          @ok="submitForm"
      >
        <div>
          <a-form-model ref="addPhoneForm" :model="addPhoneForm" :rules="addPhoneFormRules" v-bind="layout">
            <a-form-model-item label="手机号" prop="phone">
              <a-input style="width: 150px" v-model="addPhoneForm.phone"/>
            </a-form-model-item>
            <a-form-model-item label="验证码" prop="newPhoneCode">
              <a-row>
                <a-col :span="12">
                  <a-input v-model="addPhoneForm.code" style="width: 150px"/>
                </a-col>
                <a-col :span="12">
                  <CodeButton @click="sendAddPhoneCode"></CodeButton>
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>

    </div>

  </div>
</template>

<script>
import {sendOrignPhoneCode, sendNewPhoneCode, changePhone,existPhone,bindPhone} from "@/api/customer"
import CodeButton from "@/components/CodeButton";
import {isPhoneAvailable} from "@/utils/reg";

export default {
  name: "PhoneSettingTab",
  components: {
    CodeButton
  },
  data() {
    let validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        if (value === this.$store.state.user.userInfo.phone) {
          callback(new Error('不能输入相同手机号'));
        }
        if (!isPhoneAvailable(value)) {
          callback(new Error('请输入正确手机号或者邮箱号'));
        }
        callback();
      }
    };
    let validateAddPhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        if (value === this.$store.state.user.userInfo.phone) {
          callback(new Error('不能输入相同手机号'));
        }
        if (!isPhoneAvailable(value)) {
          callback(new Error('请输入正确手机号或者邮箱号'));
        }
        //是否已经绑定手机号
        existPhone(value)
        .then((res)=>{
          if(res.data.data){
            this.hasSamePhone = true
            callback("手机号已存在");
          }else {
            this.hasSamePhone = false
            callback();
          }
        })



      }
    };
    return {
      layout: {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
      },
      hasSamePhone: false,
      showAddPhoneModal:false,
      bindPhoneCofirmLoading: false,
      showBindPhoneModal: false,
      changePhoneFormRules: {
        newPhone: [{required: true, validator: validatePhone, trigger: 'blur'}],
        originPhoneCode: [{required: true, validator: "请输入验证码", trigger: 'blur'}],
        newPhoneCode: [{required: true, message: "请输入验证码", trigger: 'blur'}],
      },
      changePhoneForm: {
        newPhone: "",
        originPhoneCode: "",
        newPhoneCode: "",
      },
      addPhoneForm:{
        phone:"",
        code:"",

      },
      addPhoneFormRules: {
        phone: [{required: true, validator: validateAddPhone, trigger: 'change'}],
        code: [{required: true, validator: "请输入验证码", trigger: 'blur'}],
      },

    }
  },
  methods: {
    submitForm(){
      this.bindPhoneCofirmLoading = true
      this.$refs['addPhoneForm'].validate(valid => {
        if (valid){
          bindPhone(this.addPhoneForm)
          .then(()=>{
            this.showAddPhoneModal = false
            this.bindPhoneCofirmLoading = false
            this.$store.commit("user/getUserInfo")
          })
          .catch(()=>{
            this.bindPhoneCofirmLoading = false
          })


        }else {
          this.bindPhoneCofirmLoading = false
          this.$message.error("请填写正确信息")
        }
      })
    },
    //发送绑定手机验证码
    sendAddPhoneCode(callback){
      if (this.hasSamePhone){
        callback(false)
        this.$message.error("手机号已注册")
      }
      sendNewPhoneCode(this.addPhoneForm.phone)
      callback(true)
    },
    showPhoneModal() {
      if (this.$store.state.user.userInfo.phone !== null) {
          //打开修改手机号
        this.showBindPhoneModal = true
      }else {
        //打开添加手机号
        this.showAddPhoneModal = true
      }
    },
    submitChangeForm() {
      this.bindPhoneCofirmLoading = true
      this.$refs['changePhoneForm'].validate(valid => {
        if (valid) {
          changePhone(this.changePhoneForm)
              .then(() => {
                this.$message.success("修改成功")
                this.showBindPhoneModal = false;
                this.bindPhoneCofirmLoading = false;
                //刷新用户详情
                this.$store.commit("user/signOut")
              })
              .catch(() => {
                this.bindPhoneCofirmLoading = false;
              })


        } else {
          this.bindPhoneCofirmLoading = false;
          this.$message.error("请填写正确信息")

        }
      })
    },
    sendOriginPhoneCode(callback) {
      sendOrignPhoneCode()
      callback(true)
    },
    sendNewPhoneCode(callback) {
      let result = false
      if (this.changePhoneForm.originPhoneCode === '') {
        this.$message.error("请输入原手机验证码")
      } else {
        if (!isPhoneAvailable(this.changePhoneForm.newPhone) || this.changePhoneForm.newPhone === this.$store.state.user.userInfo.phone) {
          this.$message.error("请输入正确手机号")
        } else {
          sendNewPhoneCode(this.changePhoneForm.newPhone)
          result = true
        }

      }


      callback(result)
    }
  }
}
</script>

<style scoped>

.text-info {
  width: 100%;
  height: 100%;
}

.phone-icon {
  height: 100%;
  width: 100px;
  line-height: 200px;
  font-size: 90px;
  color: #7EBA19;
}

.info {
  width: 400px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.phone-setting {
  width: 600px;
  height: 300px;
  margin: 0 auto;
  background-color: #F8FCF1;
  padding-top: 50px;
}

.phone-description {
  width: 600px;
  height: 300px;
  margin: 0 auto;

}

.phone-setting-container {
  padding: 60px 20px 20px;
  height: 600px;
}

</style>