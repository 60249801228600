export function isPhoneAvailable(poneInput) {
    const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    if (!phoneReg.test(poneInput)) {
        return false
    } else {
        return true
    }
}

export function isEmailAvailable(emailInput) {
    const mailReg = /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
    if (!mailReg.test(emailInput)) {
        return false
    } else {
        return true
    }
}

export function isPasswordAvailable(value) {
    const reg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/
    return reg.test(value)
}

export function isIdcard(value){
    const reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    return reg.test(value)
}