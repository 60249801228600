<template>
  <div class="sell-card-container">
    <div  class="">
        <img src="~@/assets/image/contactUs2.jpg" alt="" >
    </div>
    <div class="about">
        <div class="wrap">
            <h3>企业介绍</h3>
            <div class="p-text">
                <div class="p-tl">
                    <div>
                      <p align="left">
                        镇江欧雅信息科技有限公司成立于2020年，其打造的173销卡网（www.173xiaoka.com ），是运营关于卡券权益交易服务的网站，公司成立以来一直致力于卡券交易、卡券兑换、卡券消费、卡券资讯服务等业务的合作和拓展。 暂时只针对网站公布的卡券产品交易处理，主营业务卡券权益转让交易。
                      </p>
                      <p align="left">目前支持交易的卡有：京东E卡&nbsp; &nbsp; 苏宁礼品卡&nbsp; 百联OK卡&nbsp; 盒马&nbsp; &nbsp; &nbsp;家乐福&nbsp; 沃尔玛等礼品卡&nbsp; &nbsp;携程卡&nbsp; &nbsp;出行卡券 视频会员卡&nbsp; 加油卡&nbsp; 话费卡&nbsp; 美食券&nbsp; &nbsp;游戏卡等。后期会逐步的发布更多的卡券权益交易，详情请参考卡券交易网站公示。</p></div>
                </div>
                <div class="p-tr"><img src="~@/assets/image/company.png" alt="企业介绍"></div>
            </div>
        </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Index",
  data() {
    return {
      customRate: 0,//供货折扣
      isSingleSubmit: true,//是否单笔订单
      currentCardPriceId: 0,//当前面值id
      singeSubmitCard: {
        account: "",
        password: "",
      },
      multiSubmiteCardList: [],
      isReadProtocol: false,
      isAccessProtocol: false,
      discount: "",
      currentCardTypeId: 0,
      currentCardId: 0,
      cardTypeList: [],
      currentCardList: [],
      currentCardPriceList: [],
      cardTypeId: ""
    }
  },
  components:{
    Footer
  },
  created() {
    
  },

  mounted() {
    // console.log("=============" + this.cardTypeId)
    // this.selectCardType(this.cardTypeId)
  },
  watch:{
    // "$route": "monitorAPP"
    $route: {
      handler:function(){
        this.$nextTick(function(){  //页面加载完成后执行
          // console.log("=============" + val+oldVal)
          this.selectCardType(this.$route.query.cardTypeId)
        })
      },
      // 深度观察监听
      deep: true
    }
  },
  computed: {
    //当前面值
    currentCardPrice() {
      if (this.currentCardPriceId == 0) {
        return {}
      }
      return this.currentCardPriceList.filter(value => value.id === this.currentCardPriceId)[0]
    },
    //当前选择卡种
    currentCard() {
      if (this.currentCardId == 0) {
        return {}
      }
      return this.currentCardList.filter(value => value.id === this.currentCardId)[0]
    },
    currentCarmi() {
      let carmiRule = this.currentCard.carmiRule;
      if (carmiRule == null) {
        return {}
      }
      return {
        accountLen: carmiRule.split(":")[0],
        passwordLen: carmiRule.split(":")[1]
      }
    }
  },

  methods: {
    

  }
}
</script>

<style scoped>

h3 {
    display: block;
    font-size: 2.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

div {
    display: block;
}

.wechat-banner[data-v-e6981518] {
    position: relative;
    width: 100%;
    height: 350px;
    
}
   
.public-contern[data-v-e6981518] {
    width: 100%;
    background: #fff;
    overflow: hidden;
}

.wechat-banner .w1320[data-v-e6981518] {
    position: relative;
}

.w1320 {
    width: 1320px;
    margin: 0 auto;
}

.about {
    padding-top: 60px;
    margin-bottom: 30px;
    background: #fdfdfd;
}
* {
    margin: 0;
    padding: 0;
    font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
}

div {
    display: block;
}

.about .wrap {
    position: relative;
    height: 478px;
}
.wrap {
    width: 1200px;
    margin: 0 auto;
}

.about .wrap .p-text {
    margin-top: 40px;
    color: #514644;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 1;
}

.about .wrap .p-text .p-tl {
    width: 54%;
    margin-right: 40px;
}

.about .wrap .p-text p {
    margin-bottom: 20px;
    line-height: 32px;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.about .wrap .p-text .p-tr {
    width: 27%;
    text-align: center;
}

.about .wrap .p-text {
    margin-top: 40px;
    color: #514644;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 1;
}
</style>

