<template>
  <div class="sell-card-container">
    <div style="width: 1350px;margin: 0 auto;display: flex;flex-wrap: wrap">
      <!--      卡类-->
      <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px">
        选择卡类:
      </div>
      <div style="width: 1262px">
        <div :key="cardType.id" v-for="cardType in cardTypeList" :ref="cardType.id" :id="cardType.id"
             @click="selectCardType(cardType.id)"
             class="card-type-card" :style="`background:url('${cardType.backgroundImage}') no-repeat`">
             <!-- <img style="display: block;margin: 15px auto 0;height: 36px" :src="cardType.backgroundImage" alt=""> -->
          {{ cardType.name }}
          <a-icon class="icon" :type="cardType.smallIcon"/>
          
        </div>
      </div>
      <!--      卡种-->
      <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px;margin-top: 20px">
        选择卡种:
      </div>
      <div style="width: 1262px">
        <div @click="selectCard(card.id,card.name)" :id="'card-'+card.id" :key="card.id" v-for="card in this.currentCardList"
             class="card-card">
          <a-tag style="position: absolute;left: 0;top: 0" color="#87d068">
            自动
          </a-tag>
          <a-tag v-if="card.isFixedPrice" style="position: absolute;right: -8px;top: 0" color="#87d068">
            {{ card.rate }}折
          </a-tag>
          <img style="display: block;margin: 15px auto 0;height: 36px" :src="card.smallIcon" alt="">
          <div
              style="color: #666;margin: 0 auto;height: 18px;line-height: 18px;text-overflow: ellipsis;font-size: 14px;text-align: center;margin-top: 10px">
            {{ card.name }}
          </div>
        </div>
      </div>


      <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px;margin-top: 20px">
        选择面值:
      </div>
      <div style="width: 1262px">
        <div :id="'card-price-'+cardPrice.id" @click="selectCardPrice(cardPrice.id,cardPrice.price)" :key="cardPrice.id"
             v-for="cardPrice in this.currentCardPriceList" class="card-card-price">
          <div style="color:#272727;text-align: center;height: 38px;line-height: 38px;font-size: 24px;margin-top: 10px">
            ￥{{ cardPrice.price }}
          </div>
          <div v-if="cardPrice.isFixedPrice"
               style="height:30px;margin: 0 auto;font-size:14px;line-height: 30px;text-align: center">
            <span style="color:#E8433C;">({{ cardPrice.rate }}折)/</span><span
              style="color:#8C8B8D;">￥{{ Number(cardPrice.price * cardPrice.rate / 100).toFixed(3) }}</span>
          </div>
          <div style="height:30px;margin: 0 auto;font-size:14px;line-height: 30px;text-align: center;color: red" v-else>
            回收价由供货折扣定
          </div>
        </div>

      </div>
      <!-- <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px;margin-top: 20px">
      </div>
      <div style="width: 1262px;margin-top: 15px">
        <a-alert :message="'（仅供参考）'+ msg.cardName + msg.cardPrice + '元面值最近1张回收成功的卡密，折扣是'+ msg.userRate + '，提交时间' + msg.finishTime + '（仅供参考)'" type="success"
                 showIcon>
          <div>
            <a-icon type="container"/>
          </div>
        </a-alert>
      </div> -->


      <div style="width: 88px;height: 10px">
      </div>
      <div style="width: 1262px;border-bottom: 1px dashed #e2e2e2">
      </div>


      <div v-if="this.currentCardPriceId!=0&&!this.currentCardPrice.isFixedPrice" style="display: flex">
        <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px;margin-top: 20px">
          供货折扣:
        </div>
        <div style="width: 1262px;padding-top: 35px">
          <!--          <el-input :disabled="!this.$store.getters['user/isLogin']" style="margin-top: 35px;width: 400px;margin-left: -862px" v-model="discount"-->
          <!--                    placeholder="允许输入的折扣是97%-97.60%"></el-input>-->
          <a-col :span="12">
            <a-slider :disabled="!this.$store.getters['user/isLogin']" v-model="customRate" :step="0.1"
                      :min="this.currentCardPrice.minRate" :max="this.currentCardPrice.maxRate"/>
          </a-col>
          <a-col :span="4">
            <a-input-number :disabled="!this.$store.getters['user/isLogin']" v-model="customRate"
                            :min="this.currentCardPrice.minRate" :max="this.currentCardPrice.maxRate"
                            style="marginLeft: 16px"/>
            折
          </a-col>
        </div>
      </div>
      <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px;margin-top: 20px">
        提交方式:
      </div>
      <div style="width: 1262px">
        <div style="width: 300px;margin-left: 0;margin-top: 45px">
          <el-switch
              v-model="isSingleSubmit"
              active-text="单笔提交"
              inactive-text="批量提交">
          </el-switch>
        </div>
      </div>
      <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px;margin-top: 20px">
      </div>
      <div style="width: 1262px">
        <a-alert
            :message="`【卡密规则】 卡号为${this.currentCarmi.accountLen}位，卡密为${this.currentCarmi.passwordLen}位；因卡号和卡密不匹配，或者面值不正确，如造成损失后果自负！`"
            type="warning" show-icon/>
      </div>
      <div style="display:flex;" v-if="!this.$store.getters['user/isLogin']">
        <div style="width: 88px;">
        </div>
        <div style="width: 1262px">
          <div class="login-tip">
            <a-icon type="warning"/>
            登录账号后才能进行销卡，
            <router-link to="/signin">立即登录</router-link>
          </div>
        </div>
      </div>
      <div style="display:flex;" v-if="this.$store.getters['user/isLogin'] && !this.$store.getters['user/realNameStatus']">
        <div style="width: 88px;">
        </div>
        <div style="width: 1262px">
          <div class="login-tip">
            <a-icon type="warning"/>
            实名认证后才能进行销卡，
            <router-link to="/center/baseManage?tab=relName">实名认证</router-link>
          </div>
        </div>
      </div>
      <div v-if="!isSingleSubmit" style="display: flex">
        <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px;margin-top: 20px">
          卡号/卡密:
        </div>
        <div style="width: 1262px;position: relative;">
        <textarea :style="`cursor: ${this.$store.getters['user/isLogin'] || this.$store.getters['user/realNameStatus'] ?'':'not-allowed'}`" @focus="inputCarmi"
                  id="carmiTextarea" @input="carmiTextareaChange">

        </textarea>

          <div class="prompt">
            <div style="font-size: 84px;color: #d25851;padding-top: 20px">
              <a-icon type="warning"/>
            </div>
            <div
                style="width: 504px;margin-left: 55px;text-align: left;color: #d25851;font-size: 14px;line-height: 36px;">
              卡号与卡密之间请用英文或者中文逗号或者空格隔开，<br>
              每张卡占用一行用“回车键”隔开，例如：<br>
              0111001702060546320，100304016583597830 <br>
              0111001702060546321，105754083583500026 <br>
            </div>
          </div>
        </div>
      </div>

      <div style="display:flex;" v-else>
        <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px;margin-top: 20px">
          充值卡号:
        </div>
        <div>
          <a-input :disabled="!this.$store.getters['user/isLogin'] || !this.$store.getters['user/realNameStatus']" v-model="singeSubmitCard.account"
                   style="height: 40px;line-height: 70px;font-size: 16px;margin-top: 30px" placeholder="请输入充值卡号"/>
        </div>
        <div style="width: 88px;height: 70px;line-height: 70px;font-size: 16px;margin-top: 20px">
          输入卡密:
        </div>
        <div>
          <a-input :disabled="!this.$store.getters['user/isLogin'] || !this.$store.getters['user/realNameStatus']" v-model="singeSubmitCard.password"
                   style="height: 40px;line-height: 70px;font-size: 16px;margin-top: 30px" placeholder="请输入充值卡密"/>
        </div>

      </div>

      <div style="width: 1350px;font-size: 16px">
        
        <div style="display: flex;margin-top: 10px">
          <a-checkbox @change="readProtocolCheckBoxChange" checked>
          </a-checkbox>
          我已阅读，理解并同意<a href="/#/helpInfo?id=16&categoryId=1" target="_blank">《转让协议》</a>
        </div>
        <div style="display: flex;margin-top: 10px">
          <a-checkbox @change="acceptProtocolCheckBoxChange" checked>
          </a-checkbox>
          本站只提供来路合法的网游游戏充值卡或话费充值卡寄售，严禁提交来源非法卡券，用户对自己的寄售行为承担法律责任。
        </div>

      </div>


      <div style="width: 1350px">
        <div style="width: 400px;margin: 30px auto">
          <a-button @click="showComfirm" type="primary" block :disabled="!this.$store.getters['user/isLogin'] || !this.$store.getters['user/realNameStatus']">
            确认提交
          </a-button>
        </div>
      </div>

      <div>
      <a-modal
          v-model="showAddOrderModal"
          title="提交确认"
          centered
          @ok="submitOrder"
          width="35%"
      >
        <div>
          <div class="">
            <div style="font-size: 15px;color: #d25851;padding-top: 0px">
              <a-icon type="warning"/>切记面值选择错误，则可能全单损失，批量提交失效卡密，账号和资金会被永久冻结!若卡密总面值超过当日限额，超出的可能会被自动忽略
            </div>
          </div>
          <a-form-model ref="addOrderForm" >
            
            <a-form-model-item label="" prop="">
              <div style="background-color: #F4F9FE;">
              <a-row>
                <a-col align="left" :span="10">提交 卡种</a-col>
                <a-col align="right">{{msg.cardName}}}</a-col>
              </a-row>
              <a-row>
                <a-col align="left" :span="10">选择 面值</a-col>
                <a-col align="right">{{msg.cardPrice}}元</a-col>
              </a-row>
              <a-row>
                <a-col align="left" :span="10">提交 卡密</a-col>
                <a-col align="right">{{msg.count}}张</a-col>
              </a-row>
              <a-row>
                <a-col align="left" :span="10">重复 卡密</a-col>
                <a-col align="right">{{msg.repeatCount}}张</a-col>
              </a-row>
              <a-row>
                <a-col align="left" :span="10">实际 提交</a-col>
                <a-col align="right">{{msg.realCount}}张</a-col>
              </a-row>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>
    </div>


    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import {getAllCardType, getAllCard, getAllCardPrice} from "@/api/card"
import orderApi from '@/api/order'

export default {
  name: "Index",
  data() {
    return {
      customRate: 0,//供货折扣
      isSingleSubmit: false,//是否单笔订单
      currentCardPriceId: 0,//当前面值id
      singeSubmitCard: {
        account: "",
        password: "",
      },
      multiSubmiteCardList: [],
      isReadProtocol: true,
      isAccessProtocol: true,
      showAddOrderModal:false,
      discount: "",
      currentCardTypeId: 0,
      currentCardId: 0,
      cardTypeList: [],
      currentCardList: [],
      currentCardPriceList: [],
      cardTypeId: "",
      msg: {
        cardName: "",
        cardPrice: "",
        finishTime: "",
        finalRate: "",
        userRate: ""
      },
      showComfirmLoading: false
    }
  },
  components:{
    Footer
  },
  created() {
    console.log("user isLogin == " +  this.$store.getters['user/isLogin'])
    console.log("user realNameStatus == " +  this.$store.getters['user/realNameStatus'])
    this.getCardTypes()
    // this.cardTypeId = this.$route.query.cardTypeId;
  },

  mounted() {
    // console.log("=============" + this.cardTypeId)
    // this.selectCardType(this.cardTypeId)
  },
  watch:{
    // "$route": "monitorAPP"
    $route: {
      handler:function(){
        this.$nextTick(function(){  //页面加载完成后执行
          // console.log("=============" + val+oldVal)
          this.selectCardType(this.$route.query.cardTypeId)
        })
      },
      // 深度观察监听
      deep: true
    }
  },
  computed: {
    //当前面值
    currentCardPrice() {
      if (this.currentCardPriceId == 0) {
        return {}
      }
      return this.currentCardPriceList.filter(value => value.id === this.currentCardPriceId)[0]
    },
    //当前选择卡种
    currentCard() {
      if (this.currentCardId == 0) {
        return {}
      }
      return this.currentCardList.filter(value => value.id === this.currentCardId)[0]
    },
    currentCarmi() {
      let carmiRule = this.currentCard.carmiRule;
      if (carmiRule == null) {
        return {}
      }
      return {
        accountLen: carmiRule.split(":")[0],
        passwordLen: carmiRule.split(":")[1]
      }
    }
  },

  methods: {
    submitOrder() {
      this.showAddOrderModal = false
      let currentCardPriceId = this.currentCardPriceId
      let singleSubmit = this.isSingleSubmit
      let singeSubmitCard = this.singeSubmitCard
      let currentCarmi = this.currentCarmi
      //是否选择面值
      if (currentCardPriceId === 0) {
        this.$message.error("请选择面值")
        return
      }
      //验证卡号卡密
      if (singleSubmit) {
        if (singeSubmitCard.account.length != currentCarmi.accountLen || singeSubmitCard.password.length != currentCarmi.passwordLen) {
          this.$message.error("请输入正确卡密格式")
          return;
        }
      } else {
        //多笔提交卡密
        let textArea = document.getElementById("carmiTextarea");
        let carmiValue = textArea.value;
        this.multiSubmiteCardList = carmiValue.split("\n")
            .map(value => {
              return {
                account: value.split(" ")[0],
                password: value.split(" ")[1]
              }
            })
        //验证批量提交不能超过1000
        if(this.multiSubmiteCardList.length > 4000){
          this.$message.error("一次最多提交4000张卡，请确认")
          return ;
        }
        //验证卡密是否正确
        this.multiSubmiteCardList.filter(card => {
          if (card.account.length != currentCarmi.accountLen || card.password.length != currentCarmi.passwordLen) {
            this.multiSubmiteCardList.splice(this.multiSubmiteCardList.indexOf(card),1)
            //this.$message.error("请输入正确卡密格式")
            //return;
          }
        })
      }
      //是否阅读协议
      if (!this.isReadProtocol || !this.isAccessProtocol) {
        this.$message.error("请阅读和授权协议!")
        return;
        
      }
      if (!singleSubmit) {
        // 批量提交
        orderApi.batchSubmit({
          customRate: this.customRate,
          cardPriceId: this.currentCardPriceId,
          cardList: this.multiSubmiteCardList,
          orderType: this.multiSubmiteCardList.length == 1 ? 0 : 1
        }).then(()=>{
          this.showAddOrderModal = false
          this.$message.success("提交成功!")
          //this.$router.push("/center/order/allOrder")
          document.getElementById("carmiTextarea").value = ""
        })

      }else {
        // 单笔提交
        orderApi.batchSubmit({
          customRate: this.customRate,
          cardPriceId: this.currentCardPriceId,
          cardList: [this.singeSubmitCard],
          orderType: 0
        }).then(()=>{
          this.showAddOrderModal = false
          this.$message.success("提交成功!")
          // this.$router.push("/center/order/allOrder")
          this.singeSubmitCard.account = ""
          this.singeSubmitCard.password = ""
        })
      }

    },
    readProtocolCheckBoxChange(e) {
      this.isReadProtocol = e.target.checked;
    },
    acceptProtocolCheckBoxChange(e) {
      this.isAccessProtocol = e.target.checked;
    },
    showComfirm(){
      this.showAddOrderModal = true ;
      let textArea = document.getElementById("carmiTextarea");
      let carmiValue = textArea.value;
      this.multiSubmiteCardList = carmiValue.split("\n")
          .map(value => {
            return {
              account: value.split(" ")[0],
              password: value.split(" ")[1]
            }
          })

      this.msg.count = this.multiSubmiteCardList.length
      this.msg.repeatCount = 0
      this.msg.realCount = 0
      var obj = {};
      for(var i=0;i<this.multiSubmiteCardList.length;i++){
        var item = this.multiSubmiteCardList[i].account
        obj[item] = (obj[item]+1) || 1 
        if(obj[item] > 1){
          this.msg.repeatCount += 1
        }
      }
      this.msg.realCount = this.msg.count - this.msg.repeatCount
    },
    inputCarmi() {
      if (!this.$store.getters["user/isLogin"]) {
        this.$message.error("请登录")
        document.getElementById("carmiTextarea").blur()
      }

      if (!this.$store.getters["user/realNameStatus"]) {
        this.$message.error("请实名认证")
        document.getElementById("carmiTextarea").blur()
      }

    },
    carmiTextareaChange() {
      let finalStr = "";
      let cardLen = this.currentCarmi.accountLen
      let passLen = this.currentCarmi.passwordLen
      let textArea = document.getElementById("carmiTextarea");
      let currentStr = textArea.value.replaceAll(" ", "").replaceAll("\n", "").replaceAll("\t", "").replaceAll("-","");
      let isSplitAccount = true;
      while (currentStr.length > 0) {
        if (isSplitAccount) {
          if (currentStr.length > cardLen) {
            let item = currentStr.substring(0, cardLen);
            finalStr = finalStr + item + " "
            currentStr = currentStr.substring(cardLen);
          } else {
            finalStr = finalStr + currentStr
            currentStr = ""
          }
          isSplitAccount = false
        } else {
          if (currentStr.length > passLen) {
            let item = currentStr.substring(0, passLen);
            finalStr = finalStr + item + "\n"
            currentStr = currentStr.substring(passLen);
          } else {
            finalStr = finalStr + currentStr;
            currentStr = "";
          }
          isSplitAccount = true
        }
      }
      textArea.value = finalStr;
      // console.log(finalStr)

    },
    selectCardPrice(cardPriceId,cardPrice) {
      debugger;
      this.msg.cardPrice = cardPrice
      this.clearCardPriceBorder()
      this.selectCardPriceById(cardPriceId)
      // this.getLastOrderByPriceId(cardPriceId)
    },
    getLastOrderByPriceId(cardPriceId){
      orderApi.getLastOrderByPriceId(cardPriceId)
          .then(res => {
            console.log(res);
            debugger
            this.msg.finalRate = res.data.data.finalRate == null ? "" : res.data.data.finalRate;
            this.msg.userRate = res.data.data.finalRate == null ? "" : res.data.data.userRate;
            this.msg.finishTime =  res.data.data.finishTime == null ? "" : res.data.data.finishTime ;
          })
    },
    selectCard(cardId,cardName) {
      this.clearCardBorder()
      this.selectCardById(cardId)
      this.getAllCardPriceList(cardId)
      this.msg.cardName = cardName
    },
    selectCardType(id) {
      this.clearCardTypeBorder()
      this.selectCardTypeById(id)
      this.getAllCardList(id)
    },
    getCardTypes() {
      getAllCardType()
          .then(res => {
            this.cardTypeList = res.data.data.cardTypeList
            this.$nextTick(() => {
              this.selectCardType(this.cardTypeList[0].id)
            })
          })
    },
    getAllCardList(cardTypeId) {
      getAllCard(cardTypeId)
          .then(res => {
            this.currentCardList = res.data.data.cardList
            if (this.currentCardList.length > 0) {
              this.$nextTick(() => {
                this.selectCard(this.currentCardList[0].id,this.currentCardList[0].name)
              })
            }
          })
    },
    getAllCardPriceList(cardId) {
      getAllCardPrice(cardId)
          .then(res => {
            this.currentCardPriceList = res.data.data.cardPriceList
            if (this.currentCardPriceList.length > 0) {
              this.$nextTick(() => {
                this.selectCardPrice(this.currentCardPriceList[0].id,this.currentCardPriceList[0].price)
              })
            }
          })
    },
    clearCardTypeBorder() {
      let elements = document.querySelectorAll(".card-type-card");
      elements.forEach(element => {
        element.style.border = "";
      })
    },
    clearCardBorder() {
      let elements = document.querySelectorAll(".card-card");
      elements.forEach(element => {
        element.style.border = "2px solid #DEE7F8";
      })
    },
    clearCardPriceBorder() {
      let elements = document.querySelectorAll(".card-card-price");
      elements.forEach(element => {
        element.style.border = "2px solid #DEE7F8";
      })
    },
    selectCardTypeById(id) {
      this.currentCardTypeId = id
      this.currentCardId = 0
      this.currentCardPriceId = 0
      this.currentCardPriceList = []
      this.clearCardBorder()
      let element = document.getElementById(id);
      let style = element.style;
      style.border = "3px solid #26AAFE"
    },
    selectCardById(id) {
      this.currentCardId = id
      this.currentCardPriceList = []
      this.currentCardPriceId = 0
      let element = document.getElementById("card-" + id);
      let style = element.style;
      style.border = "2px solid #27baff"
    },

    selectCardPriceById(id) {
      this.currentCardPriceId = id
      let element = document.getElementById("card-price-" + id);
      let style = element.style;
      style.border = "2px solid #27baff"
    },

  }
}
</script>

<style scoped>
.login-tip {
  width: 287px;
  height: 42px;
  line-height: 42px;
  color: #ff2020;
  border: 1px solid red;
  border-radius: 4px;
}

.prompt {
  width: 504px;
  height: 282px;
  background-color: #F4F9FE;
  position: absolute;
  right: 0px;
  top: 0px;
}

textarea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-indent: 0;
  word-wrap: normal;
  margin: 0;
  font-family: Tahoma;
  background-attachment: local;
  height: 282px;
  border: 2px solid #e4ecfa;
  border-radius: 4px;
  color: #333;
  padding: 0 504px 0 10px;
  line-height: 34px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAABGAgMAAAD3F//IAAAADFBMVEUAAADZ2dn////8/PzqPRvWAAAAAnRSTlMAxOqay5EAAAAfSURBVCjPY1iFBEY5g5wTwIpAqJz/SGCUM8g5OKMRAEbSltuayfbpAAAAAElFTkSuQmCC) 0 0;
  outline: none;
}

.active {
  border: 2px solid #27baff
}

.card-card-price {
  width: 180px;
  height: 96px;
  font-size: 18px;
  line-height: 70px;
  border-radius: 8px;
  margin-right: 30px;
  float: left;
  margin-top: 10px;
  cursor: pointer;
  color: white;
  text-align: left;
  overflow: hidden;
  position: relative;
  border: 2px solid #DEE7F8;
  background-color: #F3F7FF;
}

.card-card {
  width: 180px;
  height: 96px;
  font-size: 18px;
  line-height: 70px;
  border-radius: 8px;
  margin-right: 30px;
  float: left;
  margin-top: 10px;
  cursor: pointer;
  color: white;
  text-align: left;
  overflow: hidden;
  position: relative;
  border: 2px solid #DEE7F8;
  background-color: #F3F7FF;
}

.card-card:hover {
  border: 2px solid #27baff
}


.sell-card-container {
  width: 100%;
  height: 400px;
  background-color: #FFFFFF;
}

.card-type-card {
  width: 180px;
  height: 70px;
  font-size: 18px;
  line-height: 70px;
  border-radius: 8px;
  margin-right: 30px;
  float: left;
  margin-top: 10px;
  cursor: pointer;
  color: white;
  padding-left: 24px;
  text-align: left;
  overflow: hidden;
  position: relative;
}

.icon {
  position: absolute;
  font-size: 28px;
  position: absolute;
  font-size: 38px;
  right: 10px;
  bottom: -15px;
  transition: all 0.3s ease;
}

.card-type-card:hover {
  filter: contrast(1.8);
}

.card-type-card:hover .icon {
  transform: translateY(-30px);
}

.card-operation[data-v-5335f4ae] {
    padding: 15px 0 0;
    overflow: hidden;
}

.card-operation .card-operation-btn[data-v-5335f4ae] {
    float: left;
    width: 132px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 6px;
    background: #fddb00;
    color: #333;
    font-size: 14px;
}

.card-operation-txt[data-v-5335f4ae] {
    float: left;
    line-height: 42px;
    padding-left: 42px;
    font-size: 14px;
    color: #999;
}

.txt-red {
    color: #d25851!important;
}

.txt-orange {
    color: #f38318!important;
}
</style>