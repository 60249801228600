import api from './index'
import interfaces from "../constant/interfaces"


export function getBankCodeByCardNumber(cardNumber){
    return api.get(`${interfaces.GET_INTERFACE(interfaces.GET_BANK_CODE_BY_CARD_NUMBER)}`,{
        params:{
            cardNumber
        }
    })
}

/**
 * 获取所有银行
 */
export function bankList() {
    return api.get(`${interfaces.GET_INTERFACE(interfaces.BANK_LIST)}`)
}
