<template>
  <div style="background-color:white;height: 800px;overflow: scroll">
    <div style="height: 100px;line-height: 100px;font-size: 20px;text-align: left;padding-left: 50px">提现记录</div>
    <div class="search-from">
      <a-row>
        <a-col :span="3" style="line-height: 32px">提现时间:</a-col>
        <a-col :span="5">
          <a-range-picker
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
              :placeholder="['开始时间', '结束时间']"
              @change="onChange"
              @ok="onOk"
          />
        </a-col>
        <a-col :offset="3" :span="1">
          <a-button @click="searchOrder" type="primary">
            确认查询
          </a-button>
        </a-col>
      </a-row>
    </div>

    <div style="width: 100%;margin-top: 40px">
      <a-table
          :rowKey="(record)=>record.orderId"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
      >
        <span slot="type" slot-scope="type">
          {{ ["","银行卡","支付宝","微信"][type] }}
        </span>
        <span slot="finalAmount" slot-scope="record">
          {{ record.withDrawAmount - record.fee }}
        </span>
        <span slot="status" slot-scope="status">
          {{ ["提现中", "提现中", "提现成功", "提现失败"][status] }}
        </span>
      </a-table>
    </div>


  </div>
</template>

<script>
import orderApi from '@/api/order'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    scopedSlots: {customRender: 'createTime'},
  },
  {
    title: '提现方式',
    dataIndex: 'type',
    scopedSlots: {customRender: 'type'},
  },
  {
    title: '姓名',
    dataIndex: 'realName',
    scopedSlots: {customRender: 'realName'},
  },
  {
    title: '账号',
    dataIndex: 'account',
    scopedSlots: {customRender: 'account'},
  },
  {
    title: '提现金额',
    dataIndex: 'withDrawAmount',
    scopedSlots: {customRender: 'withDrawAmount'},
  },
  {
    title: '手续费',
    dataIndex: 'fee',
    scopedSlots: {customRender: 'fee'},
  },
  {
    title: '实际到账金额',
    scopedSlots: {customRender: 'finalAmount'},
  },
  {
    title: '申请时间',
    dataIndex: 'createTime',
    scopedSlots: {customRender: 'createTime'},
  },
  {
    title: '处理时间',
    dataIndex: 'processTime',
    scopedSlots: {customRender: 'processTime'},
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'},
  },

];

export default {
  name: "Index",
  data() {
    return {
      data: [],
      loading: false,
      searchForm: {
        createTimeBegin: null,
        createTimeEnd: null
      },
      columns,
      pagination: {
        current: 1,
        total: 100,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        pageSize: 10,
        showSizeChanger: true

      }
    }
  },
  created() {
    this.search()
  },
  mounted() {
  },
  methods: {

    search() {
      orderApi.drawList({
        ...this.searchForm,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
        }).then(res => {
            console.log(res.data.data)
            this.pagination.total = res.data.data.total
            this.data = res.data.data.data
          })

    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.search()

    },
    fetch(params = {}) {
      console.log('params:', params);
    },
    searchOrder() {
      console.log(this.searchForm)
    },
    onChange(value, dateString) {
      this.searchForm.createTimeBegin = dateString[0]
      this.searchForm.createTimeEnd = dateString[1]
    },
    onOk(value) {
      this.searchForm.createTimeBegin = value[0].format('YYYY-MM-DD HH:mm:ss')
      this.searchForm.createTimeEnd = value[0].format('YYYY-MM-DD HH:mm:ss')
    },
    handleChange(value) {
      this.searchForm.type = value
    },
    handleStatusChange(value) {
      this.searchForm.status = value
    }
  }
}
</script>

<style scoped>

</style>