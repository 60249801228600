import api from './index'
import interfaces from "../constant/interfaces"


export function list(){
    return api.get(`${interfaces.GET_INTERFACE(interfaces.BANNER_LIST)}`)
}

export default {
    list
}
