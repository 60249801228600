<template>
  <div class="base-info-container">
    <div class="base">
      <div class="base-card">
        <div style="height: 100%;line-height: 68px;font-size: 20px;margin-left: 10px;color: #6FD1FD">
          <a-icon type="idcard"/>
        </div>
        <div style="margin-left: 10px;height: 100%;line-height: 68px;color: #A3A3A3">用户编号</div>
        <div style="height: 100%; line-height: 68px; position: absolute;right: 70px">{{
            userInfo.id
          }}
        </div>
        <div @click="showSecretKey"
             style="height: 100%; line-height: 68px; color: #26AAFE;text-decoration: underline;cursor: pointer;position: absolute;right: 25px">
          密钥
        </div>
      </div>
      <div class="base-card">
        <div style="height: 100%;line-height: 68px;font-size: 20px;margin-left: 10px;color: #6FD1FD">
          <a-icon type="star"/>
        </div>
        <div style="margin-left: 10px;height: 100%;line-height: 68px;color: #A3A3A3">用户昵称</div>
        <div style="height: 100%; line-height: 68px;position: absolute;right: 70px">
          {{ userInfo.nickName == null ? '未设置' : userInfo.nickName }}
        </div>
        <div @click="showNickname"
             style="height: 100%; line-height: 68px; color: #26AAFE;text-decoration: underline;cursor: pointer;position: absolute;right: 25px">
          {{ userInfo.nickName == null ? '设置' : '修改' }}
        </div>
      </div>
      <div class="base-card">
        <div style="height: 100%;line-height: 68px;font-size: 20px;margin-left: 10px;color: #6FD1FD">
          <a-icon type="user"/>
        </div>
        <div style="margin-left: 10px;height: 100%;line-height: 68px;color: #A3A3A3">真实姓名
        </div>
        <div v-if="userInfo.realName!=null"
             style="height: 100%; line-height: 68px; position: absolute;right: 70px"><span
            style="color: #82AA1C">(已实名)</span>{{ userInfo.realName == null ? '未设置' : userInfo.realName }}
        </div>
        <div v-else style="height: 100%; line-height: 68px; position: absolute;right: 70px"><span
            style="color: red">未实名</span>
        </div>
        <div @click="gotoRealName"
            style="height: 100%; line-height: 68px;color: #26AAFE;text-decoration: underline;cursor: pointer;position: absolute;right: 25px">
          {{ userInfo.realName != null ? '查看' : '去实名' }}
        </div>
      </div>
      <div class="base-card">
        <div style="height: 100%;line-height: 68px;font-size: 20px;margin-left: 10px;color: #6FD1FD">
          <a-icon type="qq"/>
        </div>
        <div style="margin-left: 10px;height: 100%;line-height: 68px;color: #A3A3A3">联系QQ</div>
        <div style="height: 100%;line-height: 68px;margin-left: 5px;color: #26AAFE">
          <a-tooltip>
            <template slot="title">
              <div style="color: #26AAFE;">请设置您的QQ号码，方便我们在处理您提交的卡密有问题时能及时联系到您。</div>
            </template>
            <a-icon type="exclamation-circle"/>
          </a-tooltip>
        </div>
        <div style="height: 100%; line-height: 68px; position: absolute;right: 70px">
          {{ userInfo.qq != null ? userInfo.qq : '未添加' }}
        </div>
        <div @click="showQQ"
             style="height: 100%; line-height: 68px; color: #26AAFE;text-decoration: underline;cursor: pointer;position: absolute;right: 25px">
          {{ userInfo.qq != null ? '修改' : '添加' }}
        </div>
      </div>
      <div class="base-card">
        <div style="height: 100%;line-height: 68px;font-size: 20px;margin-left: 10px;color: #6FD1FD">
          <a-icon type="star"/>
        </div>
        <div style="margin-left: 10px;height: 100%;line-height: 68px;color: #A3A3A3">手机绑定</div>
        <div style="height: 100%; line-height: 68px; position: absolute;right: 70px">
          {{ userInfo.phone != null ? userInfo.phone : '未绑定' }}
        </div>
        <div @click="gotoBindPhone"
            style="float:right;height: 100%; line-height: 68px; color: #26AAFE;text-decoration: underline;cursor: pointer;position: absolute;right: 25px">
          {{ userInfo.phone == null ? '绑定' : '更换' }}
        </div>
      </div>
      <div class="base-card">
        <div style="height: 100%;line-height: 68px;font-size: 20px;margin-left: 10px;color: #6FD1FD">
          <a-icon type="star"/>
        </div>
        <div style="margin-left: 10px;height: 100%;line-height: 68px;color: #A3A3A3">邮箱绑定</div>
        <div style="height: 100%; line-height: 68px; position: absolute;right: 70px">
          {{ userInfo.email == null ? '未绑定' : userInfo.email }}
        </div>
        <div @click="gotoBindEmail"
            style="height: 100%; line-height: 68px; color: #26AAFE;text-decoration: underline;cursor: pointer;position: absolute;right: 25px">
          {{ userInfo.phone == null ? '绑定' : '更换' }}
        </div>
      </div>
      <div class="base-card">
        <div style="height: 100%;line-height: 68px;font-size: 20px;margin-left: 10px;color: #6FD1FD">
          <a-icon type="star"/>
        </div>
        <div style="margin-left: 10px;height: 100%;line-height: 68px;color: #A3A3A3">QQ绑定</div>
        <div style="height: 100%; line-height: 68px;position: absolute;right: 70px">已绑定</div>
        <div
            style="height: 100%; line-height: 68px; position:absolute;right:25px;color: #26AAFE;text-decoration: underline;cursor: pointer">
          更换
        </div>
      </div>
      <div class="base-card">
        <div style="height: 100%;line-height: 68px;font-size: 20px;margin-left: 10px;color: #6FD1FD">
          <a-icon type="star"/>
        </div>
        <div style="margin-left: 10px;height: 100%;line-height: 68px;color: #A3A3A3">微信绑定</div>
        <div style="height: 100%; line-height: 68px; position: absolute;right: 70px">已绑定</div>
        <div
            style="height: 100%; line-height: 68px; color: #26AAFE;text-decoration: underline;cursor: pointer;position: absolute;right: 25px">
          更换
        </div>
      </div>

      <a-modal
          title="商户API对接密钥"
          :visible="showSecretKeyModal"
          :confirm-loading="showNicknameModalConfirmLoading"
          @ok="chanceChangeSecretKey"
          @cancel="chanceChangeSecretKey"
      >
        {{changeSecretKey}}

      </a-modal>
      
      <a-modal
          title="修改用户昵称"
          :visible="showNicknameModal"
          :confirm-loading="showNicknameModalConfirmLoading"
          @ok="changeNickName"
          @cancel="chanceChangeNickname"
      >
        <a-input placeholder="昵称为2-20个字符，汉字、字母、数字、字符组成" allow-clear v-model="changedNickname"/>

      </a-modal>

      <a-modal
          title="设置QQ"
          :visible="showQQModal"
          :confirm-loading="showQQModalConfirmLoading"
          @ok="changeQQ"
          @cancel="chanceChangeQQ"
      >
        <a-input placeholder="请输入QQ号码" allow-clear v-model="changedQQ"/>

      </a-modal>
    </div>


  </div>
</template>

<script>
import {mapState} from 'vuex'
import {changeNickName, changeQQ} from '@/api/customer'

export default {
  name: "BaseInfoTab",
  data() {
    return {
      changedQQ: "",
      changedNickname: "",
      changeSecretKey: "",
      showNicknameModal: false,
      showNicknameModalConfirmLoading: false,
      showQQModal: false,
      showQQModalConfirmLoading: false,
      showSecretKeyModal: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    gotoRealName(){
      this.$emit("toRealName")
    },
    gotoBindPhone(){
      this.$emit("toBindPhone")
    },
    gotoBindEmail(){
      this.$emit('toBindEmailTab')
    },
    changeQQ() {
      if (this.changedQQ === '') {
        this.$message.error("请输入QQ!")
        return
      }
      this.showQQModalConfirmLoading = true;
      changeQQ(this.changedQQ)
          .then(() => {
            this.showQQModalConfirmLoading = false
            this.showQQModal = false
            this.$store.commit('user/getUserInfo')
          })
          .catch(() => {
            this.showQQModalConfirmLoading = false
          })
    },
    showNickname() {
      this.changedNickname = this.userInfo.nickName
      this.showNicknameModal = true
    },
    showSecretKey() {
      this.changeSecretKey = this.userInfo.secretKey
      this.showSecretKeyModal = true
    },
    showQQ() {
      this.changedQQ = this.userInfo.qq
      this.showQQModal = true
    },
    changeNickName() {
      if (this.changedNickname === '') {
        this.$message.error("请输入昵称!")
        return
      }

      this.showNicknameModalConfirmLoading = true;
      changeNickName(this.changedNickname)
          .then(() => {
            this.showNicknameModalConfirmLoading = false
            this.showNicknameModal = false
            this.$store.commit('user/getUserInfo')
          }).catch(() => {
        this.showQQModalConfirmLoading = false
      })

    },
    chanceChangeNickname() {
      this.showNicknameModal = false
    },
     chanceChangeSecretKey() {
      this.showSecretKeyModal = false
    },
    chanceChangeQQ() {
      this.showQQModal = false
    }
  }
}
</script>

<style scoped>
.base-card {
  width: 400px;
  height: 68px;
  background-color: #F3F7FF;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.base-info-container {

  padding-top: 50px;
  height: 600px;
}

.base {
  margin: 0 auto;
  width: 860px;
  height: 440px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

</style>