import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
//Element-UI
import {Dialog, Button, Select, Option, Input, Switch,Tag} from 'element-ui'

Vue.use(Dialog)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Switch)
Vue.use(Tag)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.config.productionTip = false
Vue.use(Antd);
Vue.filter("phoneEnc", (value) => {
    return value.substr(0, 3) + '****' + value.substr(7);
})
Vue.filter("idCardEnc", (value) => {
    return value.replace(/^(\d{6})\d+(\d{4})$/, "$1******$2");
})
Vue.filter("cardEnc", (value) => {
    return `${value.substring(0, 4)} ${"********"}${value.length % 4 ? " " : ""}${value.slice(-4)}`
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

